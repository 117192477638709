import Vue from 'vue'
import VueRouter from 'vue-router'
// 页面组件
import Home from '../views/Home.vue'
import ApprovalCenter from '../views/ApprovalCenter.vue'
import DataManagement from '../views/DataManagement.vue'
import DataBoard from '../views/DataBoard.vue'
import ManagementBackend from '../views/ManagementBackend.vue'
import Navigate from '../views/navigate.vue'
import Create from '@/views/create.vue'
import OperationRecord from '@/views/OperationRecord.vue'
// import { set } from 'core-js/core/dict'

Vue.use(VueRouter)

const routes = [
  {
    path: '/main/workbench/Workflow',
    name: 'Navigate',
    component: Navigate,
    redirect: '/main/workbench/Workflow/Navigate/home',
    children: [
      {
        path: '/main/workbench/Workflow/Navigate/home',
        name: 'Home',
        component: Home,
        meta: {
          title: "首页",
          icon: "ioc/home-dash.png",
        },
      },
      {
        path: '/main/workbench/Workflow/Navigate/approvalCenter',
        name: 'ApprovalCenter',
        component: ApprovalCenter,
        meta: {
          title: "审批中心",
          icon: "ioc/hourglass.png",
        },
      },
      {
        path: '/main/workbench/Workflow/Navigate/dataManagement',
        name: 'DataManagement',
        component: DataManagement,
        meta: {
          title: "数据管理",
          icon: "ioc/chart-bar-alt.png",
          menuId: '1733020752463454210'
        },
      },
      {
        path: '/main/workbench/Workflow/Navigate/OperationRecord',
        name: 'OperationRecord',
        component: OperationRecord,
      },
      // {
      //   path: '/main/workbench/Navigate/dataBoard',
      //   name: 'DataBoard',
      //   component: DataBoard,
      //   meta: {
      //     title: "数据看板",
      //     icon: "ioc/eye.png",
      //   },
      // },
    ]
  },
  {
    path: '/main/workbench/Workflow/create',
    name: 'Create',
    component: Create,
  },
]

export default routes


