<template>
  <el-dialog title="审批流程" :visible.sync="dialogVisible" :width="width" v-if="dialogVisible"
    :modal-append-to-body="false" :custom-class="'evaluate-dialog'">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" @close="close"
      label-position="top">
      <p v-if="message == 1" style="font-size: 18px;"><i class="el-icon-success"
          style="margin-right: 10px;color: #67C23A;"></i>您已成功提交该表单，是否需通过该审批？</p>
      <el-form-item label="" prop="reason">
        <div v-if="!pass" class="required-ioc">*</div>
        <el-input type="textarea" v-model="ruleForm.reason" placeholder="请填写审批建议"></el-input>
      </el-form-item>
    </el-form>
    <div class="tag">
      <p style="margin-bottom: 8px">推荐回复</p>
      <div class="tag_list">
        <el-tag style="cursor: pointer" @click="chooseTag(item)" v-for="(item, index) in tagList" :key="index">{{ item
          }}</el-tag>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <div @click="dialogVisible = false" class="cancel">
        取消
      </div>
      <div @click="fangd('ruleForm')" class="submit">
        确定
      </div>
    </span>
  </el-dialog>
</template>

<script>
  import { debounce } from 'lodash-es';
  import { approveTask, rejectTask } from "@/api/bpm/task";
  // import { getType } from "@/utils"
  export default {
    props: {
      taskId: {
        type: String,
        default: null,
      },
      processInstance: {
        type: Object,
        default: () => { },
      },
      detailForm: {
        type: Object,
        default: () => { },
      },
      candidate_dept: {
        type: [String, Number],
        default: null,
      },
      width: {
        type: String,
        default: '30%'
      }
    },
    watch: {
      pass(val) {
        this.rules.reason[0].required = !val;
      },
    },
    created() {
      // 初始化防抖函数，并确保其可重复使用
      this.debouncedSubmit = debounce(this.submitForm, 500);
    },
    computed: {
      tagList() {
        let arr1 = [
          "同意",
          "确认",
          "ok",
          "情况属实",
          "已审核",
          "好的",
          "通过",
          "已核实",
          "可以",
        ];
        let arr2 = [
          "拒绝",
          "不同意",
          "取消",
          "作废",
          "重复提交",
          "未解决",
          "请核实",
          "请补充材料",
        ];
        return this.pass ? arr1 : arr2;
      },
    },
    data() {
      return {
        debouncedSubmit: null,
        loading: false,
        dialogVisible: false,
        pass: true,
        ruleForm: {
          reason: "",
        },
        rules: {
          reason: [{ required: false, message: "请填写备注", trigger: "change" }],
        },
        message: 0
      };
    },
    methods: {
      chooseTag(text) {
        this.ruleForm.reason = text;
      },
      close() {
        this.ruleForm.reason = "";
        this.message = 0
        this.dialogVisible = false
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.handleAudit();
          } else {
            //   console.log("error submit!!");
            return false;
          }
        });
      },

      fangd(formName) {
        // 使用已经创建的防抖函数
        this.debouncedSubmit(formName);
      },


      getVal(item, key) {
        if (item.__config__.tagIcon === "upload") {
          if (typeof item.__config__[key] == 'string') {
            item.__config__[key] = JSON.parse(item.__config__[key])
          }
          let i = item.__config__[key].map(item => item.fileName).join(",")
          return i
        } else if (item.__config__.tagIcon === "checkbox" || item.__config__.tagIcon === "date-range" || item.__config__.tagIcon === "time-range") {
          return item.__config__[key].join(",")
        } else if (item.__config__.tagIcon === "cascader") {
          if (!Array.isArray(item.__config__[key])) {
            item.__config__[key] = [item.__config__[key]]
          }
          return item.__config__[key].map((cascaderItem) => {
            const findItem = item.options.find((optionItem) => {
              return cascaderItem === optionItem.value
            })

            if (findItem) {
              return findItem.label
            }
          }).filter((mapItem) => {
            return mapItem !== undefined
          }).join(",")
        } else {
          return item.__config__[key] + ""
        }
      },
      getRecords(data) {
        return this.detailForm.fields.reduce((acc, item) => {

          if (item.__vModel__) {
            item.__config__.newVal = this.processInstance.formVariables[item.__vModel__]
            acc.push({
              processDefinitionId: this.processInstance.processDefinition.id,
              processInstanceId: this.processInstance.id,
              fieldId: item.__vModel__,
              fieldName: item.__config__.label,
              oldVal: this.getVal(item, "defaultValue"),
              newVal: this.getVal(item, "newVal"),
              type: item.__config__.type,
              tagIcon: item.__config__.tagIcon
            });
          } else {
            if (item.__config__.children.length > 0) {
              item.__config__.children.forEach((child) => {
                if (Array.isArray(child)) {
                  child.forEach((subChild) => {
                    subChild.__config__.newVal = this.processInstance.formVariables[subChild.__vModel__]
                    acc.push({
                      processDefinitionId: this.processInstance.processDefinition.id,
                      processInstanceId: this.processInstance.id,
                      fieldId: subChild.__vModel__,
                      fieldName: subChild.__config__.label,
                      oldVal: this.getVal(subChild, "defaultValue"),
                      newVal: this.getVal(subChild, "newVal"),
                      type: subChild.__config__.type,
                      tagIcon: subChild.__config__.tagIcon
                    });
                  });
                } else {
                  child.__config__.newVal = this.processInstance.formVariables[child.__vModel__]
                  acc.push({
                    processDefinitionId: this.processInstance.processDefinition.id,
                    processInstanceId: this.processInstance.id,
                    fieldId: child.__vModel__,
                    fieldName: child.__config__.label,
                    oldVal: this.getVal(child, "defaultValue"),
                    newVal: this.getVal(child, "newVal"),
                    type: child.__config__.type,
                    tagIcon: child.__config__.tagIcon
                  });
                }
              });
            }
          }
          return acc;
        }, [])
      },
      async handleAudit() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.loading = true;
        const params = {
          id: this.taskId,
          ...this.ruleForm,
          variables: this.processInstance.formVariables,
          records: this.getRecords()
        };
        // console.log(`output->params`,params)
        // return
        if (this.candidate_dept) {
          params.variables.candidate_dept = this.candidate_dept;
        }
        // console.log(`output->this.detailForm.fields`, this.detailForm.fields)
        // console.log(`output->params`, params)
        let digestsList = params.records.filter(e => e.tagIcon == 'input')
        let digest = []
        if (digestsList.length > 0) {
          digest = digestsList.map(e => {
            return {
              name: e.fieldName,
              value: e.newVal,
            }
          })
        }
        params.digest = JSON.stringify(digest);
        const { data } = this.pass
          ? await approveTask(params)
          : await rejectTask(params);
        this.loading = false;
        this.dialogVisible = false;
        // this.$modal.msgSuccess(this.pass ? "审批通过成功！" : "审批不通过成功！");
        // 增加时间
        loading.close();
        this.$message({
          message: this.pass ? "审批通过成功！" : "审批不通过成功！",
          type: 'success',
          duration: 2000,
          onClose: () => {
            this.$emit("auditSucceed");
          }
        })
      },
    },
  };
</script>

<style lang="scss" scoped>
  .required-ioc {
    color: red;
    position: absolute;
    top: -8px;
    left: -10px;
  }

  .tag_list {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  ::v-deep .evaluate-dialog {
    background: rgba(250, 251, 252, 1);

    .el-dialog__header {
      display: flex;
      padding: 20px 20px 10px;

      .el-dialog__headerbtn {
        top: 8px;
        right: 4px;
      }
    }

    .el-dialog__body {
      padding: 10px 20px 0px 20px;

      .el-textarea__inner {
        border: 0px;
        min-height: 200px !important;
      }

      .tag {
        text-align: left;
        margin-bottom: 20px;

        .title {
          margin-bottom: 12px;
          color: rgba(27, 27, 27, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
          line-height: 13px;
        }

        .tag_list {
          span {
            border-radius: 100px;
            background: rgba(86, 105, 236, 0.05);
            padding: 6px 16px;
            display: flex;
            color: rgb(86, 105, 236);
            font-family: "Noto Sans SC";
            font-weight: 400;
            font-size: 14px;
            line-height: 13px;

          }
        }
      }

      .file>div {
        width: 136px;
        height: 58px;
        border-radius: 8px;
        border: 1px dashed rgba(199, 199, 201, 1);
        box-sizing: border-box;
        background: rgba(250, 251, 252, 1);
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }

    .el-dialog__footer {
      .dialog-footer {
        display: flex;
        justify-content: flex-end;

        .cancel {
          width: 84px;
          height: 36px;
          border-radius: 4px;
          border: 1px solid rgba(243, 244, 246, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(132, 132, 132, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
        }

        .submit {
          width: 84px;
          height: 36px;
          border-radius: 4px;
          background: rgba(86, 105, 236, 1);
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(255, 255, 255, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
</style>