<template>
  <main>
    <el-empty :description="tip" v-if="!tasks.length"
      image='https://source.fungsong.com/3452097265742FYF1wx7cNHae.png'></el-empty>


    <template v-else>
      <div class="item" v-for="(item, index) in tasks" :key="index">
        <div class="left">
          <div>
            <img class="user_img" :src="getTaskImg(item)" alt="">
            <!-- <img class="user_img_active" src="@/assets/ioc/check-double.png" alt=""> -->
          </div>
          <div class="line" v-if="index != tasks.length - 1"></div>
        </div>
        <div class="right">
          <div class="descriptions" v-if="item.result == 9999">
            <img :src="item.avatarUrl || require(`@/assets/ioc/Group_41.png`)" alt="" style="width: 24px;height: 24px;">
            <div class="label">{{ item.startUserName }}</div>
            <div class="value">发起了审批</div>
          </div>
          <div class="descriptions">
            <!-- <div class="label">{{ item.result == 9999 ? "流程" : "任务" }}</div> -->
            <div class="task">{{ item.name }}</div>
          </div>
          <!-- 审批方式 -->
          <!-- <div class="descriptions" v-if="item.approveType">
            <div class="label">审批方式</div>
            <div class="value">{{ getApproveType(item.approveType) }}</div>
          </div> -->

          <!-- 审批人 -->
          <div class="descriptions" v-if="item.assigneeUsers">
            <!-- <div class="label">
              {{ item.taskType == "USER_TASK" ? "审批人" : "抄送人" }}
            </div> -->
            <template v-if="item.optionList && item.optionList.length">
              <optionalUser :taskdata="item.optionList[0]" :users="users" :taskDefKey="item.taskDefKey"
                @chooseUser="chooseUser" />
            </template>
            <div>
              <div class="value scrollBarHidden" v-if="item.assigneeUsers.length == 1">
                <!-- <img v-if="item.assigneeUsers[0].avatarUrl" :src="item.assigneeUsers[0].avatarUrl" alt="" />
                <img v-else src="/static/img/20240118touxiang.0dcbf29b.png" alt="" /> -->
                <p style="white-space: nowrap;">
                  {{ item.assigneeUsers[0].userName.indexOf("(节点审批人为空，已转交管理员)") == -1 ? item.assigneeUsers[0].userName :
                  item.assigneeUsers[0].userName.substring(0,
                  item.assigneeUsers[0].userName.indexOf("(节点审批人为空，已转交管理员)")) }}
                </p>
                <!-- <el-tooltip class="item" effect="dark" content="节点审批人为空，已转交管理员" placement="top-start"
                  v-if="item.assigneeUsers[0].userName.indexOf('(节点审批人为空，已转交管理员)') != -1">
                  <i class="el-icon-warning"></i>
                </el-tooltip> -->
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                  v-if="item.assigneeUsers[0].userName.indexOf('(节点审批人为空，已转交管理员)') != -1"
                  @mouseenter="onMouseEnter(index)" @mouseleave="onMouseLeave(index)">
                  <path
                    d="M6 1C3.2385 1 1 3.2385 1 6C1 8.7615 3.2385 11 6 11C8.7615 11 11 8.7615 11 6C11 3.2385 8.7615 1 6 1ZM5.625 3.75C5.625 3.543 5.793 3.375 6 3.375C6.207 3.375 6.375 3.543 6.375 3.75V6.03552C6.375 6.24252 6.207 6.41052 6 6.41052C5.793 6.41052 5.625 6.24252 5.625 6.03552V3.75ZM6.01001 8.25C5.73401 8.25 5.50745 8.026 5.50745 7.75C5.50745 7.474 5.729 7.25 6.005 7.25H6.01001C6.28651 7.25 6.51001 7.474 6.51001 7.75C6.51001 8.026 6.28601 8.25 6.01001 8.25Z"
                    fill="#848484" />
                </svg>
                <div class="tooltip"
                  v-if="item.assigneeUsers[0].userName.indexOf('(节点审批人为空，已转交管理员)') != -1 && item.showText">
                  节点审批人为空 已转交管理员
                </div>


                <!-- <el-tag type="info" size="mini" v-if="item.assigneeUsers[0].deptName">{{
                  item.assigneeUsers[0].deptName
                  }}</el-tag> -->
              </div>
              <div v-else class="value_list" :ref="`featureRef`+index">
                <div class="value scrollBarHidden" v-for="(sub, i) in item.assigneeUsers" :key="i">
                  <img v-if="sub.avatarUrl" :src="sub.avatarUrl" alt="" />
                  <img v-else src="@/assets/ioc/Group_41.png" alt="" />
                  <!-- <img v-if="sub.avatarUrl" :src="sub.avatarUrl" alt="" />
                  <img v-else src="@/assets/ioc/Group_41.png" alt="" /> -->
                  <p style="white-space: nowrap;">
                    {{ sub.userName.indexOf("(节点审批人为空，已转交管理员)") == -1 ? sub.userName : sub.userName.substring(0,
                    sub.userName.indexOf("(节点审批人为空，已转交管理员)")) }}
                  </p>
                  <el-tooltip class="item" effect="dark" content="节点审批人为空，已转交管理员" placement="top-start"
                    v-if="sub.userName.indexOf('(节点审批人为空，已转交管理员)') != -1">
                    <i class="el-icon-warning"></i>
                  </el-tooltip>
                  <!-- <el-tag type="info" size="mini" v-if="sub.deptName">{{
                    sub.deptName
                    }}</el-tag> -->
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="openButton" v-if="expandTheEvent(index)">
            <img src="@/assets/ioc/Group_1000005519.png" alt="">
            <div>展开</div>
          </div> -->

          <!-- <div class="openButton" v-if="expandTheEvent">
            <img src="@/assets/ioc/Group_1000005517.png" alt="">
            <div>收起</div>
          </div> -->

          <!-- 状态 -->
          <div class="descriptions" v-if="item.result == 10">
            <div class="label">状态</div>
            <div class="value">已转交</div>
          </div>
          <!-- 耗时 -->
          <div class="descriptions" v-if="item.result == 1">
            <div class="label">已等待</div>
            <div class="value">{{ diffTime(item.createTime) }}</div>
          </div>
          <!-- 备注 -->
          <div class="reason" v-if="item.reason">{{ item.reason }}</div>
          <!-- 评论 -->
          <template v-if="item.comments && item.comments.length">
            <div class="reason" v-for="(e, i) in item.comments" :key="i">
              <div style="margin-bottom: 8px" v-if="e.isWithdraw == 0">
                <div class="name">
                  <p style="margin-bottom: 8px">{{ e.userName }}:</p>
                  <template v-if="userId == e.userId">
                    <el-button type="text" @click="deleteComment(e)" v-if="revocationFlag(e.createTime)">撤销</el-button>
                  </template>
                </div>
                <p>{{ e.comment }}</p>
                <div class="picture_list" v-if="e.picture && e.picture.length">
                  <div v-for="(sub, p) in e.picture" :key="p">
                    <el-image style="width: 50px; height: 50px" :src="sub.url"
                      :preview-src-list="e.picture.map((a) => a.url)">
                    </el-image>
                  </div>
                </div>
                <div class="attachment_list" v-if="e.attachment && e.attachment.length">
                  <div v-for="(sub, p) in e.attachment" :key="p">
                    <p>{{ sub.name }}</p>
                    <div>
                      <el-link @click="preview(sub)">预览</el-link>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>评论已撤回</div>
            </div>
          </template>
        </div>
      </div>
    </template>






    <!-- 文件预览 -->
    <FilePreviewDialog :file-url="fileUrl" :fileName="fileName" :dialog-visible="openFilePreview"
      @closePreviewDialog="closePreviewDialog" :type="type" :width='(type && type != "PC") ? "90%" : "50%"'>
    </FilePreviewDialog>
  </main>
</template>

<script>
  import FilePreviewDialog from "./FilePreviewDialog.vue";
  import { listSimpleUsers } from "@/api/system/user";
  import optionalUser from "./optionalUser.vue";
  import { deleteComment } from "@/api/bpm/flow";
  import { getDate } from "@/utils/dateUtils";
  export default {
    components: {
      optionalUser,
      FilePreviewDialog,
    },
    props: ["tasks", "userId", "tip", 'type'],
    data() {
      return {
        users: [],
        bg1: "#060000",
        // bg2: "#3A9B91",
        bg2: "#00ffe5",
        bg3: "#FF424C",
        openFilePreview: false,
        fileUrl: null, // 预览本地文件，文件放置与public文件夹下
        fileName: null, //
        cssHeight: '48px'
      };
    },
    watch: {
      tasks(val) {

      },
    },
    created() {
      this.listSimpleUsers();
    },
    methods: {
      // 返回节点图片
      getTaskImg(row) {
        if (row.assigneeUsers.length == 1) {
          return row.assigneeUsers[0].avatarUrl || require(`@/assets/ioc/Group_41.png`);
        }
        return require(`@/assets/ioc/Group_1000005473.png`);
      },

      //展开
      // expandTheEvent(index) {
      //   const expand = parseFloat(this.$refs[`featureRef${index}`].style.height)
      //   if (expand > parseFloat(this.cssHeight)) {
      //     this.cssHeight = expand + "px";
      //     return true;
      //   }
      // },
      onMouseEnter(index) {
        this.$set(this.tasks[index], 'showText', true);
      },
      onMouseLeave(index) {
        this.$set(this.tasks[index], 'showText', false);
      },


      preview(e) {
        let fileType = this.getFileType(e.url);
        this.FilePreviewDialogHandle(fileType, e);
      },
      FilePreviewDialogHandle(type, file) {
        this.openFilePreview = !this.openFilePreview;
        this.fileUrl = file.url;
        this.fileName = file.name;
      },
      closePreviewDialog() {
        this.openFilePreview = !this.openFilePreview;
      },
      getFileType(fileUrl) {
        if (fileUrl !== "" && fileUrl != null && fileUrl !== undefined) {
          const fileType = fileUrl.split(".").pop().toLowerCase(); // 文件的扩展名（格式）

          return fileType;
        } else {
          return "docx";
        }
      },
      chooseUser(e) {
        this.$emit("chooseUser", e);
      },
      async listSimpleUsers() {
        const { data } = await listSimpleUsers();
        this.users = data;
      },
      getApproveType(e) {
        if (!e) return;
        let arr = [
          {
            label: "顺签",
            value: "sequence",
          },
          {
            label: "会签",
            value: "and",
          },
          {
            label: "或签",
            value: "or",
          },
        ];
        return arr.find((item) => item.value == e).label;
      },
      async deleteComment(e) {
        let flag = this.revocationFlag(e.createTime);
        if (flag) {
          const { data } = await deleteComment({ commentId: e.id });
          this.$emit("deleteComment");
        } else {
          this.$message("无法撤销");
          this.$emit("deleteComment");
        }
      },
      revocationFlag(e) {
        if (!e) return false;
        let min = 2 * 60 * 1000;
        let createTime = e + min;
        let newTime = new Date().getTime();
        return createTime < newTime ? false : true;
      },
      getDateStar(ms) {
        return getDate(ms);
      },
      diffTime(val) {
        const now = new Date().getTime();
        const future = new Date(val);
        const diff = now - future;
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        if (days > 0) {
          return `${days}天`;
        } else if (hours > 0) {
          return `${hours}小时`;
        } else if (minutes > 0) {
          return `${minutes}分钟`;
        } else {
          return `${seconds}秒`;
        }

      }
    },
  };
</script>

<style lang="scss" scoped>
  .user_img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  main {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .item {
      display: flex;
      gap: 8px;

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        .user_icon {
          width: 24px;
          height: 24px;
          border: 1px solid rgb(86, 105, 236);
          border-radius: 50%;
          box-sizing: content-box;

          .user_img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }

          .user_img_active {
            position: relative;
            top: -15px;
            left: 15px;
          }
        }


        .dot {
          width: 12px;
          flex: 0 0 12px;
          border-radius: 50%;
          background: "#060000";
        }

        .end_dot {
          width: 12px;
          flex: 0 0 12px;
          border: 1px solid #060000;
          box-sizing: border-box;
          border-radius: 50%;
        }

        .line {
          width: 0;
          flex: 1;
          border-left: 1px dashed #c7c7c9;
        }
      }

      .right {
        width: 100%;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 14px;
        margin-top: 2px;

        .time {
          color: #060000;
        }

        .descriptions {
          display: flex;
          gap: 10px;


          .label {
            color: #060000;
            flex: 0 0 58px;
          }

          .task {
            color: rgba(27, 27, 27, 1);
            font-family: "Noto Sans SC";
            font-weight: 400;
            font-size: 13px;
          }

          .value {
            /* flex: 1; */
            width: 66px;
            height: 20px;
            color: #727272;
            display: flex;
            align-items: center;
            gap: 4px;
            flex-wrap: wrap;

            .tooltip {
              /* width: fit-content;
              height: fit-content;
              display: inline-flex;
              place-content: center;
              place-items: center;
              gap: 10px; */
              padding: 4px 8px;
              border-radius: 0px 4px 4px 4px;
              background: rgba(27, 27, 27, 1);
              /* box-shadow: 0px 2px 15px rgba(203, 208, 249, 0.25); */

              color: rgba(255, 255, 255, 1);
              font-family: "Noto Sans SC";
              font-weight: 400;
              font-size: 10px;

              position: relative;
              left: 50px;
              /* top: -5px; */
            }

            p {
              /* flex: 0 0 42px; */
              color: rgba(132, 132, 132, 1);
              font-family: "Noto Sans SC";
              font-weight: 400;
              font-size: 11px;
            }

            img {
              width: 18px;
              height: 18px;
              border-radius: 50%;
            }

            /* 
            .user_icon_small{
              width: 24px;
              height: 24px;
              border: 1px solid rgb(86, 105, 236);
              border-radius: 50%;
              box-sizing: content-box;
            } */
          }

          .value_list {
            display: flex;
            /* flex-direction: column; */
            grid-area: 8px;
            gap: 6px;
            flex-wrap: wrap;
          }
        }

        .reason {
          width: 100%;
          background: #f5f5f7;
          border-radius: 0px 12px 12px 12px;
          padding: 8px 12px;
          color: #727272;

          .name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .picture_list {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
  }

  .attachment_list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    >div {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      align-items: center;
    }
  }

  .scrollBarHidden {
    max-width: 180px;

    /* 或者 overflow: scroll; */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .scrollBarHidden::-webkit-scrollbar {
    width: 0;
    /* 或者 height: 0; */
    background: transparent;
    /* 使滚动条透明 */
  }

  .openButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .div {
      color: rgba(132, 132, 132, 1);
      font-family: "Noto Sans SC";
      font-weight: 400;
      font-size: 11px;
      line-height: 11px;
    }
  }
</style>