import request from "@/utils/request";

export function getFlowDefList(data) {
  return request({
    url: "/bpm/process-instance/getFlowDataList",
    method: "post",
    data,
  });
}

//获取人员信息
export const getUserList = (query) => {
  return request({
    url: "/system/user/list",
    method: "get",
    params: query,
  });
};

//获取人员信息
export const getModelList = (query) => {
  return request({
    url: "/bpm/process-instance/getFlowDefList",
    method: "get",
    params: query,
  });
};
//导出
export function getExportDataList(data) {
  return request({
    url: "/bpm/process-instance/export",
    method: "post",
    data,
  });
}
//删除
export function deleteFlowData(data) {
  return request({
    url: "/bpm/process-instance/deleteFlowData",
    method: "post",
    data,
  });
}

//导出列表
export function getExportList(data) {
  return request({
    url: "/bpm/process-instance/getExportDataList",
    method: "post",
    data,
  });
}

//删除列表
export function getDEList(data) {
  return request({
    url: "/bpm/process-instance/getDeleteDataList",
    method: "post",
    data,
  });
}