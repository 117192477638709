// 处理url的逻辑

import request from "@/utils/request";
import { getRelationApprovalByC } from "@/api/bpm/form";

export const fetchData = (component, formId, userId, defaultValue) => {
  // console.log(component, "component");
  if (component.__config__.label == "部门") {
    // console.log(component, "componentcomponentcomponentcomponent");
  }
  const { dataType, method, url, type } = component.__config__;
  let param = {
    method: method,
    url: url,
  };
  // if ((type == 'contacts' || type == 'litigant') && defaultValue) {
  //   param.params = {
  //     userIds: defaultValue,
  //   }   
  // }
  if (dataType === "dynamic" && method && url) {
    request(param).then((resp) => {
      const { type, selectType } = component.__config__;
      switch (type) {
        case "department": //部门
          component.options = resp.data[0].children;
          break;
        case "position": //职位
          const positionData = resp.data.map((item) => {
            return {
              label: item.postName,
              value: item.postId,
            };
          });
          component.__slot__.options = positionData;
          break;
        case "rank": //职级
          const rankData = resp.data.map((item) => {
            return {
              label: item.rankName,
              value: item.rankId,
            };
          });
          component.__slot__.options = rankData;
          break;
        case "contacts": //联系人
          const contactsData = resp.rows.map((item) => {
            return {
              label: item.userName,
              value: item.userId.toString(),
            };
          });
          component.__slot__.options = contactsData;
          console.log(`output->component`, component.__vModel__)

          // console.log(resp.data[0].children, "contacts");
          // component.options = resp.data[0].children;
          break;
        case "litigant": //实际申请人
          const litigantData = resp.rows.map((item) => {
            return {
              label: item.userName,
              value: item.userId.toString(),
            };
          });
          component.__slot__.options = litigantData;
          // console.log(resp.data[0].children, "contacts");
          // component.options = resp.data[0].children;
          break;
        case "approval": //关联审批
          // console.log("关联审批");
          // this.$axios.get('http://47.97.215.138:8080/bpm/flowManagement/relationApproval',{
          //   params: { formId: resp.data.id },
          // }).then((res)=>{
          //   console.log(res)
          // })
          break;
        default:
          break;
      }
      switch (selectType) {
        case "select": //下拉选择
          component.__slot__.options = resp.data;
          break;
        case "cascader": //级联选择
          // console.log(resp.data, "resp.data");
          component.options = resp.data;
          break;
        case "radio": //单选框组
          component.__slot__.options = resp.data;
          break;
        case "checkbox": //单选框组
          component.__slot__.options = resp.data;
          break;
        default:
      }
    });
  }
  if (dataType === "approval") {
    // const query = {
    //   // formId,
    //   // isValid: true,
    //   formIds: component.__config__.approval,
    // };

    // if (userId) {
    //   query.startUser = userId; 
    // }
    const id = JSON.parse(localStorage.getItem("store-user-info")).userInfoState.userId
    getRelationApprovalByC(component.__config__.approval, userId ? userId : id).then((resp) => {
      component.options = resp.data
    });
  }
  return component;
};

