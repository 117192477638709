<template>
  <div class="container">
    <div class="left">
      <div style="  padding-left: 20px;padding-right: 20px;">
        <header>
          <div class="detailed">
            <div
              style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
            >
              审批编号：{{ processInstance.id }}
            </div>

            <el-tooltip  effect="dark" content="分享" placement="bottom" popper-class="custom-tooltip">
              <img
              style="margin-left: 20px"
              src="@/assets/ioc/arrow-export.png"
              alt=""
              @click="shareApproval(processInstance, currentNodeId)"
            />
            </el-tooltip>

            <!-- <img
              style="margin-left: 20px"
              src="@/assets/ioc/arrow-export.png"
              alt=""
              @click="shareApproval(processInstance)"
            /> -->
          </div>

          <div class="user">
            <img
              class="info_img"
              :src="startUser.avatarUrl ||  require(`@/assets/ioc/Group_41.png`)"
              alt=""
            />
            <div class="info">
              <div class="info_top">
                <p>{{ processInstance.name }}</p>
                <dict-tag
                  type="bpm_process_instance_result"
                  :value="processInstance.result"
                />
              </div>
              <div class="info_bottom">
                <span
                  >提交于 {{ formattedTime(processInstance.createTime) }}</span
                >
              </div>
            </div>
            <img class="info_type" :src="displayStatus()" alt="" />
          </div>

          <!-- <div class="btn" id="btnDiv" v-if="checkBtnFlag">
            <el-button icon="el-icon-remove" size="mini" @click="handleCancel" v-if="chexiaoBtn">撤销</el-button>
            <el-button icon="el-icon-edit-outline" size="mini" @click="handleFqrSubmit()" v-if="fqrBtn">提交</el-button>
            <el-button icon="el-icon-s-promotion" size="mini" @click="reStartGo" v-if="reStartBtn">重新发起</el-button>
            <template>
              <el-button icon="el-icon-edit-outline" size="mini" @click="handleAudit(true)" v-if="caozuoBtn">通过
              </el-button>
              <el-button icon="el-icon-circle-close" size="mini" @click="handleAudit(false)" v-if="caozuoBtn">不通过
              </el-button>
              <el-button class="pian_btn" icon="el-icon-s-comment" size="mini" v-if="processInstance.status == 1"
                @click="openEvaluateDialog()">评论
              </el-button>
              <el-button class="pian_btn" icon="el-icon-s-comment" size="mini" v-if="processInstance.status == 2"
                @click="openEvaluateDialog(1)">评论
              </el-button>
              <el-button class="pian_btn" icon="el-icon-paperclip" size="mini" v-if="processInstance.status == 2"
                @click="openServiceTask()">抄送
              </el-button>
              <template v-if="moreFlag && caozuoBtn">
                <el-button class="pian_btn" icon="el-icon-edit-outline" size="mini" v-if="!scopeType"
                  @click="handleUpdateAssignee()">转办
                </el-button>
                <el-button icon="el-icon-refresh-left" size="mini" class="pian_btn" v-if="!scopeType && caozuoBtn"
                  @click="handleBackList()">回退
                </el-button>
                <el-button class="pian_btn" icon="el-icon-collection-tag" size="mini"
                  v-if="!scopeType && jiaqianBtn && caozuoBtn" @click="createSign()">加签
                </el-button>
              </template>
<div class="icon" @click="moreFlag = !moreFlag" v-if="!scopeType && caozuoBtn">
  <i class="el-icon-more"></i>
</div>
</template>
</div> -->
        </header>
        <main class="main">
          <div class="first_line"></div>
          <div class="info_box">
            <!-- <div class="title">
              审批详情
              <el-button type="text" @click="showUpdateLog">变更记录</el-button>
            </div> -->

            <div
              v-if="
                this.processInstance.processDefinition &&
                  this.processInstance.processDefinition.formType === 10
              "
              v-loading="parserLoading"
            >
              <parser
                ref="oArser"
                :key="datekey"
                :form-conf="detailForm"
                :disabled="true"
                :disabledReset="true"
                @submit="submit"
                @onPreview="onPreview"
                v-if="dataLoaded"
                :submitDisabled="submitDisabled"
                @changefiles="changefiles"
                :candidate_dept="candidate_dept"
              />
            </div>

            <div
              v-if="
                this.processInstance.processDefinition &&
                  this.processInstance.processDefinition.formType === 20
              "
            >
              <async-biz-form-component
                :id="this.processInstance.businessKey"
              ></async-biz-form-component>
            </div>
            <!-- 关联审批,上传附件浏览 -->
            <template>
              <div
                v-for="(item, index) in detailForm.fields"
                :key="index"
              ></div>
            </template>
          </div>
          <div class="first_line"></div>
          <div class="right">
            <timeline
              :tasks="tasks"
              @deleteComment="deleteComment"
              :userId="userId"
            />
          </div>
        </main>
      </div>
      <!-- 底部 -->
      <div class="footer" id="btnDiv" v-if="checkBtnFlag">
        <div
          @click="openEvaluateDialog(processInstance.status)"
          v-if="processInstance.status == 1 || processInstance.status == 2"
        >
          <img src="@/assets/ioc/chat.png" alt="" />
          <div class="footer-text">评论</div>
        </div>
        <div @click="handleCancel" v-if="chexiaoBtn">
          <img src="@/assets/ioc/rotate-left.png" alt="" />
          <div class="footer-text">撤销</div>
        </div>
        <div @click="reStartGo" v-if="reStartBtn">
          <img src="@/assets/ioc/arrow-up-right.png" alt="" />
          <div class="footer-text">重新发起</div>
        </div>
        <div
          @click="handleUpdateAssignee"
          v-if="!scopeType && caozuoBtn && !scopeType"
        >
          <img src="@/assets/ioc/arrows-repeat.png" alt="" />
          <div class="footer-text">转办</div>
        </div>
        <div v-if="(!scopeType && caozuoBtn) || processInstance.status == 2">
          <el-dropdown
            :render-to-body="false"
            trigger="click"
            @command="handleCommand"
          >
            <div>
              <img src="@/assets/ioc/more-horizontal.png" alt="" />
              <div class="footer-text">更多</div>
            </div>
            <el-dropdown-menu slot="dropdown" :append-to-body="false">
              <el-dropdown-item v-if="processInstance.status == 2" :command="1">
                <div
                  style="display: flex; align-items: center;justify-content: center;"
                >
                  <img src="@/assets/ioc/send.png" alt="" />
                  <span style="margin-left: 8px;">抄送</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item
                v-if="!scopeType && jiaqianBtn && caozuoBtn"
                :command="2"
              >
                <div
                  style="display: flex; align-items: center;justify-content: center;"
                >
                  <img src="@/assets/ioc/plus-circle.png" alt="" />
                  <span style="margin-left: 8px;">加签</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item v-if="!scopeType && caozuoBtn" :command="3">
                <div
                  style="display: flex; align-items: center;justify-content: center;"
                >
                  <img src="@/assets/ioc/arrow-back.png" alt="" />
                  <span style="margin-left: 8px;">回退</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-if="caozuoBtn">
          <div class="footer-notApproved" @click="handleAudit(false)"> 
            不通过
          </div>
          <!-- <el-button @click="handleAudit(false)">不通过</el-button> -->
        </div>
        <div v-if="caozuoBtn">
          <!-- <el-button type="primary" @click="handleAudit(true)"
            >&nbsp;通过&nbsp;</el-button
          > -->
          <div class="footer-adopt" @click="handleAudit(true)">
            通过
          </div>
        </div>
      </div>
    </div>

    <!-- 对话框(转办审批人) -->
    <el-dialog
      title="转办审批人"
      :visible.sync="updateAssignee.open"
      width="368px"
      :modal-append-to-body="false"
      :custom-class="'handover-dialog'"
    >
      <el-form
        ref="updateAssigneeForm"
        :model="updateAssignee.form"
        :rules="updateAssignee.rules"
        label-width="110px"
        :label-position="'top'"
      >
        <el-form-item label="新审批人" prop="assigneeUserId">
          <el-select
            v-model="updateAssignee.form.assigneeUserId"
            filterable
            clearable
            style="width: 100%"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="(item, index) in userOptions"
              :key="index"
              :label="item.userName"
              :value="item.userId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="转办原因" prop="reason">
          <el-input
            v-model="updateAssignee.form.reason"
            clearable
            placeholder="请输入转办原因"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div @click="cancelUpdateAssigneeForm" class="dialog-footer-btn">
          取消
        </div>
        <div
          @click="submitUpdateAssigneeForm"
          class="dialog-footer-btn-determine"
        >
          确定
        </div>
      </div>
    </el-dialog>

    <!-- 撤销流程 -->
    <el-dialog
      title="取消流程"
      :visible.sync="revokeShow"
      width="368px"
      :modal-append-to-body="false"
      :custom-class="'handover-dialog'"
    >
      <el-form
        ref="revoke"
        :model="revoke"
        v-loading="formLoading"
        :rules="revokeRules"
        label-width="110px"
        :label-position="'top'"
      >
        <el-form-item label="取消理由" prop="reasonForRevocation">
          <el-input
            v-model="revoke.reasonForRevocation"
            clearable
            placeholder="请输入取消理由"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <div @click="revokeShow = false" class="dialog-footer-btn">
          取消
        </div>
        <div @click="confirmRevocation" class="dialog-footer-btn-determine">
          确定
        </div>
      </div>
    </el-dialog>

    <!-- 对话框(委派审批人) -->
    <el-dialog
      title="委派审批人"
      :visible.sync="updateDelegate.open"
      width="500px"
      append-to-body
    >
      <el-form
        ref="updateDelegateForm"
        :model="updateDelegate.form"
        :rules="updateDelegate.rules"
        label-width="110px"
      >
        <el-form-item label="新审批人" prop="delegateUserId">
          <el-select
            v-model="updateDelegate.form.delegateUserId"
            filterable
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in userOptions"
              :key="index"
              :label="item.userName"
              :value="item.userId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="委派理由" prop="reason">
          <el-input
            v-model="updateDelegate.form.reason"
            clearable
            placeholder="请输入委派理由"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitUpdateDelegateForm"
          >确 定</el-button
        >
        <el-button @click="cancelUpdateDelegateForm">取 消</el-button>
      </div>
    </el-dialog>
    <!--退回流程-->
    <el-dialog
      title="退回流程"
      :visible.sync="returnOpen"
      width="368px"
      :modal-append-to-body="false"
      :custom-class="'handover-dialog'"
    >
      <el-form
        ref="formRef"
        v-loading="formLoading"
        :model="formData"
        :rules="formRules"
        label-width="110px"
        :label-position="'top'"
      >
        <el-form-item label="退回节点" prop="targetDefinitionKey">
          <el-select
            v-model="formData.targetDefinitionKey"
            clearable
            style="width: 100%"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="(item, index) in returnList"
              :key="index"
              :label="item.name"
              :value="item.definitionKey"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="回退理由" prop="reason">
          <el-input
            v-model="formData.reason"
            clearable
            placeholder="请输入回退理由"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <div @click="returnOpen = false" class="dialog-footer-btn">
          取消
        </div>
        <div @click="submitReturn" class="dialog-footer-btn-determine">
          确定
        </div>
      </div>
    </el-dialog>
    <!--变更记录-->
    <el-dialog
      title="变更记录"
      :visible.sync="isShowUpdateLog"
      width="40%"
      append-to-body
    >
      <el-timeline :reverse="false">
        <el-timeline-item
          v-for="(move, index) in logList"
          :key="index"
          :timestamp="new Date(move.updateTime).toLocaleString()"
          placement="top"
        >
          <div class="log-item">{{ move.description }}</div>
        </el-timeline-item>
      </el-timeline>
    </el-dialog>
    <!-- 加签弹窗 -->
    <addSign
      ref="addSign"
      :userOptions="userOptions"
      :taskId="taskId"
      :currentTaskId="currentTaskId"
      @addSuccess="addSuccess"
      :processInstance="processInstance"
      :detailForm="detailForm"
      :candidate_dept="candidate_dept"
    />
    <!-- 评论 -->
    <evaluate
      ref="evaluate"
      :width="'60%'"
      :taskId="taskId"
      :currentTaskId="currentTaskId"
      :id="id"
      @createComment="createComment"
      :tasks="tasks"
      :modelId="modelId"
    />
    <!-- 审批 -->
    <audit
      ref="audit"
      :width="'60%'"
      :taskId="taskId"
      :processInstance="processInstance"
      :detailForm="detailForm"
      @auditSucceed="goBack"
      :candidate_dept="candidate_dept"
    />
    <!-- 文件预览 -->
    <FilePreviewDialog
      :file-url="fileUrl"
      :fileName="fileName"
      :dialog-visible="openFilePreview"
      @closePreviewDialog="closePreviewDialog"
    ></FilePreviewDialog>

    <initiatingRange
      title="添加抄送人"
      ref="initiatingRange"
      :isUser="true"
      :addType="'0'"
      @confirm="confirm"
    />
  </div>
</template>

<script>
    import { debounce } from 'lodash-es';
import { parseTime } from "@/utils/ruoyi";
import FilePreviewDialog from "./components/FilePreviewDialog.vue";
import { uniq } from "lodash-es";
import { fetchData } from "@/utils/utils";
import { deepClone } from "@/utils/index";
import {
  approveTask,
  delegateTask,
  getReturnList,
  getTaskListByProcessInstanceId,
  rejectTask,
  returnTask,
  getLogList,
  updateTaskAssignee,
  simpleTaskReject,
  simpleTaskApprove,
  simpleApproveInfo
} from "@/api/bpm/task";
import {
  updateForm,
  updateFormInfo,
  getOptions,
  getTrack,
  getfindTrack,
  freshTrack,
  addSender
} from "@/api/bpm/flow";

import store from "@/store";
import { decodeFields } from "@/utils/formGenerator";
import {
  getProcessInstance,
  getExpression,
  cancelProcessInstance,
  simpleTaskInfo,
  cancelSimpleTaskRevoke
} from "@/api/bpm/processInstance";
import { getActivityList } from "@/api/bpm/activity";
import { listSimpleUsers } from "@/api/system/user";
import evaluate from "./components/evaluate.vue";
import timeline from "./components/timeline.vue";
import addSign from "./components/addSign.vue";
// import Parser from "@/components/parser/Parser";
import Parser from "@/components/DrawerParser/Parser";
import audit from "./components/audit";
import { set } from "vue";
import initiatingRange from "./components/initiatingRange";
export default {
  components: {
    timeline,
    Parser,
    addSign,
    evaluate,
    audit,
    FilePreviewDialog,
    initiatingRange
  },
  props: ["drawerData"],
  watch: {
    drawerData: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.dataLoaded = false;
          this.initial(newVal);
        }
      },
      deep: true, // 开启深度监听
      immediate: true // 立即执行一次监听函数
    }
  },
  mounted() {},
  data() {
    return {
      imgList: [
        "https://source.fungsong.com/3452562980930rw2ySusf1vv2.png", //进行中
        "https://source.fungsong.com/34543364410145RpvpQiwyTeu.png", //已通过
        "https://source.fungsong.com/3454336531559UYM9NI2KUlFM.png", //已撤销
        "https://source.fungsong.com/3454336605543pGSe1EDxJ9hu.png" //未通过
      ],
      revoke:{
        reasonForRevocation: "",
      },

      revokeShow: false,
      datekey: Date.now(),
      logList: [],
      openFilePreview: false,
      isShowUpdateLog: false,
      currentNodeId: "",
      fileUrl: null, // 预览本地文件，文件放置与public文件夹下
      fileName: null, //
      scopeType: null, //是否加签
      activityList: [],
      jiaqianBtn: false, //加签按钮
      commentFlag: false, //评价按钮
      chexiaoBtn: false, //撤销按钮
      reStartBtn: false, //重新发起
      caozuoBtn: false, //操作按钮
      taskId: null,
      btnLoading: false, //操作按钮
      currentTaskId: null,
      taskType: 0, // 1我发起的  2待办任务  3已办任务  4我收到的
      processTasks: {}, //处理中的任务
      userId: "",
      processInstance: {
        processDefinition: {
          bpmnXml: null
        }
      }, //详情
      tasks: [], //流程
      // 流程表单详情
      detailForm: {
        fields: [],
        formBtns: false
      },
      moreFlag: false, //更多按钮
      checkBtnFlag: false,

      //回退列表数据
      formLoading: false,
      returnOpen: false,
      revokeRules: {
        reasonForRevocation: [
          { required: true, message: "取消理由不能为空", trigger: "blur" }
        ]
      },
      formRules: {
        targetDefinitionKey: [
          { required: true, message: "必须选择回退节点", trigger: "change" }
        ],
        reason: [
          { required: true, message: "回退理由不能为空", trigger: "blur" }
        ]
      },
      returnList: [],
      formData: {
        id: "",
        targetDefinitionKey: undefined,
        reason: ""
      },
      // 转派审批人
      userOptions: [],
      updateAssignee: {
        open: false,
        form: {
          assigneeUserId: undefined
        },
        rules: {
          assigneeUserId: [
            { required: true, message: "新审批人不能为空", trigger: "change" }
          ],
          reason: [
            { required: true, message: "请输入转办原因", trigger: "change" }
          ]
        }
      },
      updateDelegate: {
        open: false,
        form: {
          delegateUserId: undefined,
          reason: ""
        },
        rules: {
          delegateUserId: [
            { required: true, message: "新审批人不能为空", trigger: "change" }
          ],
          reason: [
            { required: true, message: "委派理由不能为空", trigger: "blur" }
          ]
        }
      },

      bpmnControlForm: {
        prefix: "flowable"
      },
      dataLoaded: false,
      submitDisabled: false,
      fqrBtn: false,
      originalArray: {},
      origin: [],
      modelKey: [],
      candidate_dept: null,
      modelId: "",
      parserLoading: false,
      startUser: ""
    };
  },

  // created() {
  //   //
  //   this.taskType = this.$route.query.taskType;
  //   this.userId = store.getters.userId;
  //   this.id = this.$route.query.id;
  //   this.taskId = this.$route.query.taskId || null;
  //   if (!this.id) {
  //     this.$message.error("未传递 id 参数，无法查看流程信息");
  //     return;
  //   }
  //   this.getlistSimpleUsers();
  // },
  // mounted() {
  //   this.getDetail();
  // },
  methods: {
    // 初始化方法
    async initial(newVal) {
      this.taskType = newVal.taskType;
      this.userId = JSON.parse(
        localStorage.getItem("store-user-info")
      ).userInfoState.userId;
      this.id = newVal.id;
      this.taskId = newVal.taskId || null;
      await this.getlistSimpleUsers();
      this.getDetail();
    },

    // 根据状态返回图片
    displayStatus() {
      switch (this.processInstance.status) {
        case 1:
          return "https://source.fungsong.com/3454804853962D0DdExSPB3OP.png"; //进行中
          break;
        case 2:
          if (this.processInstance.result == 2) {
            return "https://source.fungsong.com/3454805255918bqlTzibTZJb4.png";//已通过
          } else {
            return "https://source.fungsong.com/3454336605543pGSe1EDxJ9hu.png";//未通过
          }
          break;
        case 3:
          return "https://source.fungsong.com/3454336531559UYM9NI2KUlFM.png";//已撤销
          break;
      }
    },

    formattedTime(time) {
      return parseTime(time);
    },

    changefiles(e) {
      this.saveRelevancy(e);
      if (e.scheme.isAssociation) {
        // console.log(`output->e.scheme`, e.scheme)
        let event = e.event;
        let newArray = [];
        let { __vModel__, __slot__ } = e.scheme;
        if (event) {
          let flag = e.event;
          let filteredItems = this.originalArray.fields.filter(
            item => item.isAssociation == true
          ); //表单中存在哪些选项关联组件
          let filteredItemsI = filteredItems.filter(
            item => item.__vModel__ !== __vModel__
          ); //获取该选项关联组件以外选项关联组件的值
          let oa = []; //其他选项关联组件选定的值
          filteredItemsI.forEach(i => {
            if (i.__config__.defaultValue) {
              let oaItem = i.__slot__.options.filter(
                e => e.value == i.__config__.defaultValue
              );
              oa.push(...oaItem[0]?.defaultChecked);
            }
          });
          let list = __slot__.options.filter(item => {
            return item.value == flag;
          });
          let arr = list[0].defaultChecked || [];
          arr.push(__vModel__);
          arr.push(...this.origin);
          arr.push(...oa);
          arr = uniq(arr);

          for (let i = 0; i < this.originalArray.fields.length; i++) {
            for (let j = 0; j < arr.length; j++) {
              if (
                (this.originalArray.fields[i].__vModel__ ||
                  this.originalArray.fields[i].__config__.componentName) ==
                arr[j]
              ) {
                if (this.originalArray.fields[i].__vModel__ == __vModel__) {
                  newArray.push(e.scheme);
                } else {
                  newArray.push(this.originalArray.fields[i]);
                  break;
                }
              } else {
                console.log("|||");
              }
            }
          }
        } else {
          newArray = this.originalArray.fields;
        }
        // console.log(`output->newArray`, newArray);
        // 找出option的数组
        setTimeout(() => {
          this.$set(this.detailForm, "fields", newArray);
          // console.log(`output->this.detailForm`, this.detailForm);
          //这里更新了datekey ，组件就会刷新
          this.datekey = Date.now();
        }, 500);
      }
    },
    saveRelevancy(e) {
      let scheme = e.scheme;
      this.originalArray.fields.map(item => {
        if (item.__vModel__) {
          if (scheme.__vModel__ == item.__vModel__) {
            item.__config__.defaultValue = scheme.__config__.defaultValue;
          }
        } else {
          item.__config__.children.forEach(row => {
            row.forEach(rowItem => {
              if (scheme.__vModel__ == rowItem.__vModel__) {
                rowItem.__config__.defaultValue =
                  scheme.__config__.defaultValue;
              }
            });
          });
        }
      });
    },
    showUpdateLog() {
      getLogList({ processInstanceId: this.id }).then(res => {
        const { code, data } = res;
        if (code == 200) {
          this.logList = data;
          this.isShowUpdateLog = true;
        }
      });
    },
    closePreviewDialog() {
      this.openFilePreview = !this.openFilePreview;
    },
    FilePreviewDialogHandle(type, file) {
      this.openFilePreview = !this.openFilePreview;
      this.fileUrl = file.url;
      this.fileName = file.name;
    },
    onPreview(file, url = "") {
      // let fileType = this.getFileType(e.url);
      // this.FilePreviewDialogHandle(fileType, e);

      this.fileUrl = url;
      this.fileName = file.name;
      this.openFilePreview = !this.openFilePreview;
    },
    getFileType(fileUrl) {
      if (fileUrl !== "" && fileUrl != null && fileUrl !== undefined) {
        const fileType = fileUrl
          .split(".")
          .pop()
          .toLowerCase(); // 文件的扩展名（格式）

        return fileType;
      } else {
        return "docx";
      }
    },
    async getTrackList() {
      let obj = {
        variables: this.processInstance.formVariables,
        definitionId: this.processInstance.processDefinition.id,
        instanceId: this.id,
        initiator: {
          avatarUrl:this.startUser.avatarUrl,
          userId: this.startUser.userId,
          userName: this.startUser.userName
        }
      };
      const { data } = await freshTrack(obj);
      return data;
    },
    linkDetails(e) {
      if (!e) return;
      let { defaultValue } = e.__config__;
      let id = defaultValue[defaultValue.length - 1];
      let { href } = this.$router.resolve(
        "/bpm/process-instance/detail?id=" + id + "&taskType=4"
      );
      window.open(href);
    },
    // 获取关联审批中文
    getApprovalName(e) {
      if (!e) return;
      let { options } = e;
      let { defaultValue } = e.__config__;
      let id = defaultValue[defaultValue.length - 1];
      let fn = arr => {
        for (let index = 0; index < arr.length; index++) {
          const item = arr[index];
          if (item.value == id) {
            return item.label;
          } else {
            if (item.children && item.children.length) {
              return fn(item.children);
            }
          }
        }
      };
      return fn(options);
    },
    // 加签成功
    addSuccess() {
      this.goBack();
    },
    // 撤回评论
    deleteComment() {
      this.getTaskListByProcessInstanceId();
    },
    // 评论成功
    createComment() {
      this.getDetail();
    },
    // 打开评论弹窗
    openEvaluateDialog(e) {
      if (e == 2) {
        this.$refs.evaluate.status = 1;
      }
      this.$refs.evaluate.dialogVisible = true;
    },
    // 打开加签弹窗
    createSign() {
      let that = this.$refs.oArser
      that.$refs[that.formConf.formRef].validate((valid) => {
        if (valid) {
          let oldObj = this.processInstance.formVariables
          let newObj = this.$refs.oArser[this.detailForm.formModel]
          this.processInstance.formVariables = { ...oldObj, ...newObj }
          // this.processInstance.formVariables =
          //   this.$refs.oArser[this.detailForm.formModel];
          this.$refs.addSign.dialogVisible = true;
        }
      });
    },
    submit(e) {
      // console.log(e.values, "eeeeeeeee");
      if (
        this.processInstance.currentTask &&
        this.processInstance.currentTask.result == 11
      ) {
        this.updateForm(e);
      } else {
        this.updateFormInfo(e);
      }
    },
    // 审批人编辑表单
    async updateFormInfo(e) {
      let obj = {
        processInstanceId: this.id,
        variables: e.values
      };
      const { data } = await updateFormInfo(obj);
      // this.$message.success("提交成功");
      this.getDetail();
      this.$refs.audit.message = 1;
      this.handleAudit(true);
    },
    // 保存表单
    async updateForm(e) {
      let obj = {
        processInstanceId: this.id,
        variables: e.values,
        taskId: this.taskId || this.currentTaskId
      };
      const { data } = await updateForm(obj);
      this.$message.success("提交成功");
      this.getDetail();
    },
    /** 返回退回节点列表 */
    handleBackList() {
      // 可参考 http://blog.wya1.com/article/636697030/details/7296

      getReturnList(this.taskId || this.currentTaskId).then(response => {
        this.returnList = response.data;
        if (this.returnList.length == 0) {
          this.$modal.msgError("当前没有可回退的节点！");
          return;
        }
        this.formData.id = this.taskId || this.currentTaskId;
        this.returnOpen = true;
      });
    },
    /** 提交退回任务 */
    submitReturn:debounce(function() {
      if (!this.formData.targetDefinitionKey) {
        this.$modal.msgError("请选择退回节点！");
      }
      this.$refs["formRef"].validate(valid => {
        if (!valid) {
          return;
        }
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.btnLoading = true;
        let oldObj = this.processInstance.formVariables
        let newObj = this.$refs.oArser[this.detailForm.formModel]
        this.processInstance.formVariables = { ...oldObj, ...newObj }
        // this.processInstance.formVariables =
        //   this.$refs.oArser[this.detailForm.formModel];
        const params = {
          ...this.formData,
          variables: this.processInstance.formVariables,
          records: this.getRecords(),
        };
        if (this.candidate_dept) {
          params.variables.candidate_dept = this.candidate_dept;
        }

        returnTask(params).then(
          res => {
            if (res.data) {
              loading.close();
              this.$modal.msgSuccess("回退成功！");
              this.goBack();
              this.returnOpen = false;
              this.btnLoading = false;
            } else {
              this.btnLoading = false;
            }
          },
          () => {
            loading.close();
            this.btnLoading = false;
            this.$modal.msgError("失败！");
          }
        );
      });
    },500),
    //退回发起人节点提交
    handleFqrSubmit() {
      console.log(this.$refs.oArser[this.detailForm.formModel]);
      let oldObj = this.processInstance.formVariables
      let newObj = this.$refs.oArser[this.detailForm.formModel]
      this.processInstance.formVariables = { ...oldObj, ...newObj }
      // this.processInstance.formVariables =
      //   this.$refs.oArser[this.detailForm.formModel];
      const params = {
        id: this.taskId || this.currentTaskId,
        reason: "",
        variables: this.processInstance.formVariables,
        records: this.getRecords()
      };
      if (this.candidate_dept) {
        params.variables.candidate_dept = this.candidate_dept;
      }
      approveTask(params).then(response => {
        this.$modal.msgSuccess("提交成功！");
        this.goBack();
      });
    },
    /** 提交委派审批人 */
    submitUpdateDelegateForm() {
      this.$refs["updateDelegateForm"].validate(valid => {
        if (!valid) {
          return;
        }
        delegateTask(this.updateDelegate.form).then(response => {
          this.$modal.msgSuccess("委派任务成功！");
          this.updateDelegate.open = false;
          this.goBack();
        });
      });
    },
    /** 处理审批委派的操作 */
    handleDelegate() {
      //this.$modal.msgError("暂不支持【委派】功能，可以使用【转派】替代！");
      this.resetUpdateDelegateForm();
      this.updateDelegate.form.id = this.taskId || this.currentTaskId;
      // 设置为打开
      this.updateDelegate.open = true;
    },
    /** 取消委派审批人 */
    cancelUpdateDelegateForm() {
      this.updateDelegate.open = false;
      this.resetUpdateDelegateForm();
    },
    /** 重置委派审批人 */
    resetUpdateDelegateForm() {
      this.updateDelegate.form = {
        id: undefined,
        delegateUserId: undefined
      };
      this.resetForm("updateDelegateForm");
    },
    /** 提交转办审批人 */
    submitUpdateAssigneeForm:debounce(function() {
      let oldObj = this.processInstance.formVariables
          let newObj = this.$refs.oArser[this.detailForm.formModel]
          this.processInstance.formVariables = { ...oldObj, ...newObj }
      this.$refs["updateAssigneeForm"].validate(valid => {
        if (!valid) {
          return;
        }
        this.updateAssignee.form.id = this.taskId || this.currentTaskId;
        const params = {
          id: this.taskId || this.currentTaskId,
          ...this.updateAssignee.form,
          variables: this.processInstance.formVariables,
          records: this.getRecords()
        };
        if (this.candidate_dept) {
          params.variables.candidate_dept = this.candidate_dept;
        }
        let digestsList = params.records.filter(e => e.tagIcon == "input");
        let digest = [];
        if (digestsList.length > 0) {
          digest = digestsList.map(e => {
            return {
              name: e.fieldName,
              value: e.newVal
            };
          });
        }
        params.digest = JSON.stringify(digest);
        // console.log(`output->params`, params)
        // return
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        updateTaskAssignee(params).then(response => {
          loading.close();
          this.$modal.msgSuccess("转办任务成功！");
          this.updateAssignee.open = false;
          this.goBack();
          // this.getDetail(); // 获得最新详情
        });
      });
    },500),
    getRecords(data) {
      // console.log(`output->fields`, this.detailForm.fields)
      return this.detailForm.fields.reduce((acc, item) => {
        // console.log(`output->item`,item)

        if (item.__vModel__) {
          item.__config__.newVal = this.processInstance.formVariables[
            item.__vModel__
          ];
          acc.push({
            processDefinitionId: this.processInstance.processDefinition.id,
            processInstanceId: this.processInstance.id,
            fieldId: item.__vModel__,
            fieldName: item.__config__.label,
            oldVal: this.getVal(item, "defaultValue"),
            newVal: this.getVal(item, "newVal"),
            type: item.__config__.type
          });
        } else {
          if (item.__config__.children.length > 0) {
            item.__config__.children.forEach(child => {
              if (Array.isArray(child)) {
                child.forEach(subChild => {
                  subChild.__config__.newVal = this.processInstance.formVariables[
                    subChild.__vModel__
                  ];
                  acc.push({
                    processDefinitionId: this.processInstance.processDefinition
                      .id,
                    processInstanceId: this.processInstance.id,
                    fieldId: subChild.__vModel__,
                    fieldName: subChild.__config__.label,
                    oldVal: this.getVal(subChild, "defaultValue"),
                    newVal: this.getVal(subChild, "newVal"),
                    type: subChild.__config__.type
                  });
                });
              } else {
                child.__config__.newVal = this.processInstance.formVariables[
                  child.__vModel__
                ];
                acc.push({
                  processDefinitionId: this.processInstance.processDefinition
                    .id,
                  processInstanceId: this.processInstance.id,
                  fieldId: child.__vModel__,
                  fieldName: child.__config__.label,
                  oldVal: this.getVal(child, "defaultValue"),
                  newVal: this.getVal(child, "newVal"),
                  type: child.__config__.type
                });
              }
            });
          }
        }
        return acc;
      }, []);
    },
    getVal(item, key) {
      if (item.__config__.tagIcon === "upload") {
        if (typeof item.__config__[key] == "string") {
          item.__config__[key] = JSON.parse(item.__config__[key]);
        }
        let i = item.__config__[key].map(item => item.fileName).join(",");
        return i;
      } else if (
        item.__config__.tagIcon === "checkbox" ||
        item.__config__.tagIcon === "date-range" ||
        item.__config__.tagIcon === "time-range"
      ) {
        return item.__config__[key].join(",");
      } else if (item.__config__.tagIcon === "cascader") {
        return item.__config__[key]
          .map(cascaderItem => {
            const findItem = item.options.find(optionItem => {
              return cascaderItem === optionItem.value;
            });

            if (findItem) {
              return findItem.label;
            }
          })
          .filter(mapItem => {
            return mapItem !== undefined;
          })
          .join(",");
      } else {
        return item.__config__[key] + "";
      }
    },
    /** 处理转办审批人 */
    handleUpdateAssignee() {
      let that = this.$refs.oArser;
      that.$refs[that.formConf.formRef].validate(valid => {
        if (valid) {
          // 设置表单
          this.resetUpdateAssigneeForm();
          this.updateAssignee.form.id = this.processTasks.id;
          // 设置为打开
          this.updateAssignee.open = true;
        }
      });
    },
    /** 重置转办审批人 */
    resetUpdateAssigneeForm() {
      this.updateAssignee.form = {
        id: undefined,
        assigneeUserId: undefined
      };
      this.resetForm("updateAssigneeForm");
    },
    /** 取消转办审批人 */
    cancelUpdateAssigneeForm:debounce(function(){
      this.updateAssignee.open = false;
      this.resetUpdateAssigneeForm();
    },500),


    // 获得用户列表
    async getlistSimpleUsers() {
      this.userOptions = [];
      const response = await listSimpleUsers();
      this.userOptions.push(...response.data);
      // .then((response) => {
      //   this.userOptions.push(...response.data);
      // });
    },

    // 审批通过/不通过
    handleAudit(pass) {
      let that = this.$refs.oArser
      console.log(that.formConf.formRef);
      that.$refs[that.formConf.formRef].validate((valid) => {
        console.log(valid);
        if (valid) {

          let oldObj = this.processInstance.formVariables
          let newObj = this.$refs.oArser[this.detailForm.formModel]
          this.processInstance.formVariables = { ...oldObj, ...newObj }
          console.log(this.processInstance.formVariables);
          // this.processInstance.formVariables =
          //   this.$refs.oArser[this.detailForm.formModel];

          this.taskId ? this.taskId : (this.taskId = this.currentTaskId);
          this.$refs.audit.dialogVisible = true;
          this.$refs.audit.pass = pass;

        }
      });
    },
    // 撤销
    handleCancel() {
      this.revokeShow = true;
    },

    // 确定撤销
    confirmRevocation:debounce(function(){
      this.$refs["revoke"].validate((valid) => {
        console.log(valid);
        if(valid){
          const id = this.processInstance.id;
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
          cancelProcessInstance(id, this.revoke.reasonForRevocation).then(() => {
            loading.close();
            this.$modal.msgSuccess("取消成功");
            this.revokeShow = false;
            this.goBack();
          });
        }else {
            return false;
          }
        })
    },500),

    

    async getDetail() {
      this.parserLoading = true;
      // 获得流程实例相关
      this.processInstanceLoading = true;

      const { data } = this.taskId
        ? await getProcessInstance(this.id, this.taskId)
        : await getProcessInstance(this.id);
      // const { data } = await getProcessInstance(this.id, this.taskId);
      this.currentTaskId = data.currentTask?.taskId;
      this.modelId = data.modelId;
      this.startUser = data.startUser;

      if (!data) {
        this.$message.error("查询不到流程信息！");
        return;
      }

      if (data.modelKey) {
        const resp = await getExpression(data.modelKey);
        this.modelKey = resp.data;
      }
      // 按钮权限
      this.setBtn(data);
      // 设置流程信息
      this.processInstance = data;
      this.$emit('setBackgColor',{status:data.status,result:data.result})
      let list = this.processInstance.processDefinition.formFields.map(e =>
        JSON.parse(e)
      );

      // 设置表单的值
      // list.forEach((item) => {
      //   if (!item.display && item.disabled == false && this.taskType == 2) {
      //     this.detailForm.formBtns = true;
      //   }
      //   const val = this.processInstance.formVariables[item.__vModel__];

      //   console.log(this.processInstance.formVariables, "val");
      //   if (val) {
      //     item.__config__.defaultValue = val;
      //   }
      // });

      if (this.processInstance.formVariables) {
        this.candidate_dept = this.processInstance.formVariables.candidate_dept
          ? this.processInstance.formVariables.candidate_dept
          : null;
        list.forEach(item => {
          if (!item.display && item.disabled == false && this.taskType == 2) {
            this.detailForm.formBtns = true;
          }

          if (item.__vModel__) {
            /* 普通表单 */
            if (this.processInstance.formVariables[item.__vModel__]) {
              this.$set(
                item.__config__,
                "defaultValue",
                this.processInstance.formVariables[item.__vModel__]
              );
            }
          } else if (item.__config__.children.length) {
            /* 容器表单 */
            const map = item.__config__.children[0].map(rowItem => {
              const havKeys = Object.keys(
                this.processInstance.formVariables
              ).filter(key => {
                if (key === rowItem.__vModel__) {
                  this.$set(
                    rowItem.__config__,
                    "defaultValue",
                    this.processInstance.formVariables[key]
                  );
                }
                return (
                  key.includes(rowItem.__vModel__) && key !== rowItem.__vModel__
                );
              });

              console.log(havKeys, "havKeys");

              return havKeys.map(key => {
                const dRowItem = deepClone(rowItem);
                dRowItem.__config__.defaultValue = this.processInstance.formVariables[
                  key
                ];
                dRowItem.__vModel__ = key;
                rowItem.__config__.defaultValue = this.processInstance.formVariables[
                  rowItem.__vModel__
                ];
                return dRowItem;
              });
            });

            if (map.flat().length) {
              map.forEach(item2 => {
                item2.forEach((item3, index) => {
                  if (item.__config__.children[index + 1]) {
                    item.__config__.children[index + 1].push(item2[index]);
                  } else {
                    item.__config__.children[index + 1] = [item2[index]];
                  }
                });
              });
            }
          }
        });
      }

      list.map(async item => {
        // return fetchData(
        //   item,
        //   data.processDefinition.formId,
        //   data.startUser.userId
        // );

        if (item.__vModel__) {
          await fetchData(
            item,
            data.processDefinition.formId,
            data.startUser.userId,
            item.__config__.defaultValue
          );
        } else {
          item.__config__.children.forEach(row => {
            row.forEach(rowItem => {
              fetchData(
                rowItem,
                data.processDefinition.formId,
                data.startUser.userId
              );
            });
          });
        }
      });
      let missingObjects = [];

      // 表单选项关联处理
      let associations = list.filter(e => e.isAssociation);
      let association = list.filter(e => e.isAssociation)[0];
      if (associations.length > 0) {
        let arr = list.map(item =>
          item.__vModel__ ? item.__vModel__ : item.__config__.componentName
        );
        const filteredItems = list.filter(item => item.isAssociation == true);
        const defaultCheckedArray = filteredItems.flatMap(item =>
          item.__slot__.options.map(option => option.defaultChecked)
        );
        const oneDimArray = defaultCheckedArray.flat();
        this.origin = arr.filter(element => !oneDimArray.includes(element));
        let defaultChecked = [];
        associations.forEach(item => {
          let oo =
            item.__slot__.options.filter(
              e => e.value == item.__config__.defaultValue
            )[0]?.defaultChecked || [];
          defaultChecked.push(...oo);
        });

        // let defaultChecked =
        //   association.__slot__.options.filter(
        //     (e) => e.value == association.__config__.defaultValue
        //   )[0]?.defaultChecked || [];

        defaultChecked.push(association.__vModel__);

        defaultChecked.push(...this.origin);

        defaultChecked = uniq(defaultChecked);
        let newArray = [];
        for (let i = 0; i < list.length; i++) {
          for (let j = 0; j < defaultChecked.length; j++) {
            if (
              (list[i].__vModel__ || list[i].__config__.componentName) ==
              defaultChecked[j]
            ) {
              if (list[i].__vModel__ == association.__vModel__) {
                newArray.push(association);
              } else {
                newArray.push(list[i]);
                break;
              }
            }
          }
        }
        let idsInB = new Set(newArray.map(item => item.__vModel__));
        missingObjects = list.filter(item => !idsInB.has(item.__vModel__));
        list = newArray;
      }

      setTimeout(() => {
        // 设置表单信息
        if (this.processInstance.processDefinition.formType === 10) {
          const currentTask = this.processInstance.currentTask;
          if (
            currentTask &&
            currentTask.result == 1 &&
            currentTask.assigneeUserId == this.userId
          ) {
            this.taskType = 2;
          }
          if (this.taskType != 2) {
            list.forEach(item => {
              if (item.__vModel__) {
                item.disabled = true;
              } else {
                item.__config__.children.forEach(row => {
                  row.forEach(rowItem => {
                    rowItem.disabled = true;
                  });
                });
              }
            });
            missingObjects.forEach(item => {
              if (item.__vModel__) {
                item.disabled = true;
              } else {
                item.__config__.children.forEach(row => {
                  row.forEach(rowItem => {
                    rowItem.disabled = true;
                  });
                });
              }
            });
            this.detailForm.formBtns = false;
          }

          // 退回打开所有编辑权限
          if (
            currentTask &&
            currentTask.result == 11 &&
            this.userId == currentTask.assigneeUserId
          ) {
            this.submitDisabled = true;

            list.forEach(e => {
              if (e.__vModel__) {
                e.disabled = false;
                e.display = false;

                if (e.__config__.type === "litigant") {
                  e.disabled = true;
                }
                if (this.modelKey.includes(e.__vModel__)) {
                  // 如果在，将e.type设置为true
                  e.disabled = true;
                }
              } else {
                e.__config__.children.forEach(childArray => {
                  childArray.forEach(child => {
                    child.disabled = false;
                    child.display = false;
                  });
                });
              }
            });

            missingObjects.forEach(e => {
              if (e.__vModel__) {
                e.disabled = false;
                e.display = false;

                if (e.__config__.type === "litigant") {
                  e.disabled = true;
                }
                if (this.modelKey.includes(e.__vModel__)) {
                  // 如果在，将e.type设置为true
                  e.disabled = true;
                }
              } else {
                e.__config__.children.forEach(childArray => {
                  childArray.forEach(child => {
                    child.disabled = false;
                    child.display = false;
                  });
                });
              }
            });

            this.detailForm.formBtns = true;
          }

          // console.log(`1112`, JSON.parse(JSON.stringify(list)))

          this.detailForm.fields = list;
          // console.log(`222222`,JSON.parse(JSON.stringify(list)))
          this.detailForm = Object.assign(
            this.detailForm,
            JSON.parse(this.processInstance.processDefinition.formConf)
          );

          if (!currentTask) {
            this.detailForm.disabled = true;
            this.detailForm.formBtns = false;
          }
          this.originalArray = { ...this.detailForm };
          let arr = JSON.parse(JSON.stringify(this.originalArray.fields));
          let combinedArray = arr.concat(missingObjects);
          let uniqueObjects = combinedArray.reduce((acc, item) => {
            if (!acc.find(obj => obj.__vModel__ === item.__vModel__)) {
              acc.push(item);
            }
            return acc;
          }, []);
          this.originalArray.fields = uniqueObjects;
        }
        this.dataLoaded = true;
        this.parserLoading = false;
      }, 2000);

      // 加载活动列表
      getActivityList({
        processInstanceId: this.processInstance.id
      }).then(response => {
        this.activityList = response.data;
      });

      this.getTaskListByProcessInstanceId();
    },

    setBtn(data) {
      const currentTask = data.currentTask;
      // 所有按钮权限  只有发起和代办就有按钮
      this.checkBtnFlag = true;

      if (
        this.userId == data.startUser.userId &&
        (data.status == 3 || data.status == 2)
      ) {
        this.reStartBtn = true;
      }

      // if (this.taskType == 4 || this.taskType == 3) {
      //   this.checkBtnFlag = false;
      // }

      // 撤销按钮
      this.chexiaoBtn = false;
      if (
        data.startUser &&
        this.userId == data.startUser.userId &&
        data.result === 1 &&
        data.isCancel == 1
        // this.taskType == 1
      ) {
        this.chexiaoBtn = true;
      }
      // 操作按钮
      this.caozuoBtn = false;
      if (currentTask && currentTask.result == 1) {
        if (currentTask.assigneeUserId) {
          this.caozuoBtn =
            this.userId == currentTask.assigneeUserId ? true : false;
        }
        // } else {
        //   this.caozuoBtn =
        //     thcurrentTaskis.candidates.filter((e) => e == this.userId).length >
        //       0
        //       ? true
        //       : false;
        // }
      }
      if (currentTask && currentTask.result == 11) {
        if (currentTask.assigneeUserId) {
          this.chexiaoBtn = currentTask.assigneeUserId ? true : false;
          this.fqrBtn =
            this.userId == currentTask.assigneeUserId ? true : false;
        }
      }

      // 评论按钮权限
      this.commentFlag = true;
      // if (currentTask && currentTask.commentEnable == 1) {
      //   this.commentFlag = true;
      // }
      // 当前任务是否加签
      this.scopeType = false;
      if (currentTask && currentTask.scopeType) {
        this.scopeType = true;
      }
      // 加签按钮权限
      this.jiaqianBtn = false;
      if (data.isSign == 1) {
        this.jiaqianBtn = true;
      }
      setTimeout(() => {
        let btnDiv = document.getElementById("btnDiv");
        // console.log(btnDiv, "btnDiv");
        if (btnDiv.childElementCount <= 0) {
          this.checkBtnFlag = false;
        }
      }, 100);
    },
    // 获取流程
    async getTaskListByProcessInstanceId() {
      const { data } = await getTaskListByProcessInstanceId(this.id);
      this.taskId = data[0].id;
      if (data.length == 1) {
        this.scopeType = true;
      }
      const track = await this.getTrackList();
      // 审批记录
      this.tasks = [];
      // 移除已取消的审批
      data.forEach(task => {
        this.tasks.push(task);
      });
      this.tasks.reverse();
      // console.log(`output->this.tasks`, this.tasks);
      if (this.tasks.length) {
        this.tasks.unshift({
          ...this.tasks[0].processInstance,
          createTime: this.tasks[0].createTime,
          result: 9999
        });
      }


      let subsequentNodes = false;
      if (
        data[0].approveType == "sequence" 
      ) {
        this.currentNodeId = data[0].taskDefKey;
        const startIndex = track.findIndex(
          obj => obj.taskDefKey === this.currentNodeId
        );
        if(startIndex>=0){
          let a = track[startIndex].assigneeUsers.length;
          let b = 0
          data.forEach(elem => {
            if (elem.taskDefKey === this.currentNodeId) {
              track[startIndex].assigneeUsers = track[
                startIndex
              ].assigneeUsers.filter(
                item => item.userId != elem.assigneeUsers[0].userId
              );
              b++
            }
          });
          subsequentNodes = track[startIndex];
          a <= b ? subsequentNodes=false : subsequentNodes;
        }
      }

      // if( data[0].approveType == "and" ){
      //   this.currentNodeId = data[0].taskDefKey;
      //   const startIndex = track.findIndex(
      //     obj => obj.taskDefKey === this.currentNodeId
      //   );
      //   if(startIndex>=0 && data[0].taskDefKey.assigneeUsers.length == track[startIndex].assigneeUsers.length){

      //   }
      // }


      let arr = [];
      let startIndex = -1;
      let i = 1;

      while (startIndex === -1 && this.tasks.length - i >= 0) {
        const lastTaskDefKey = this.tasks[this.tasks.length - i].taskDefKey;
        startIndex = track.findIndex(obj => obj.taskDefKey === lastTaskDefKey);
        i++;
      }
      if (startIndex !== -1) {
        let arr = track.slice(startIndex + 1);

        this.tasks.push(...arr);
      } else {
        console.log("未找到匹配元素");
      }

      if( subsequentNodes){
        let indexs = 0;
      for (let i = this.tasks.length - 1; i >= 0; i--) {
        if (this.tasks[i].taskDefKey == subsequentNodes.taskDefKey) {
          indexs = i;
          break;
        }
      }
      if (subsequentNodes && subsequentNodes.assigneeUsers.length > 0) {
        this.tasks.splice(indexs + 1, 0, subsequentNodes);
      }
      }
    },
    goBack() {
      // this.getDetail();
      // this.$tab.closeOpenPage();
      // if (window.history.length > 1) {
      //   this.$router.go(-1);
      // } else {
      //   const obj = { path: "/task/done" };
      //   this.$tab.closeOpenPage(obj);
      // }
      this.$emit("closeDrawer");
    },
    openServiceTask() {
      // 抄送人选用户组
      this.$store.dispatch("bpm/listDeptUser");
      this.$refs.initiatingRange.dialogVisible = true;
    },
    confirm(val) {
      // console.log(`output->val`, val)
      let userIfs = val.map(item => item.id);
      let data = {
        instId: this.id,
        userIds: userIfs
      };
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      addSender(data)
        .then(res => {
          loading.close();
          this.$message.success("添加抄送人成功!");
          this.getDetail();
        })
        .catch(err => {
          loading.close();
          this.$message.error("添加抄送人失败！");
        });
    },
    reStartGo() {
      this.$router.push({
        name: "Create",
        query: { taskType: 1, reStartId: this.id }
      });
    },

    // 更多功能
    handleCommand(command) {
      switch (command) {
        case 1:
          this.openServiceTask();
          break;
        case 2:
          this.createSign();
          break;
        case 3:
          this.handleBackList();
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  // height: calc(100vh - 82px);
  // background: #f5f5f7;
  // padding: 28px;
  box-sizing: border-box;
  // display: flex;
  // gap: 28px;

  .left {
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    // gap: 28px;
    flex-shrink: 0;
    // padding-left: 20px;
    // padding-right: 20px;

    header {
      height: 90px;

      // flex: 0 0 124px;
      // background: #fff;
      // border-radius: 30px;
      // padding: 18px;
      // box-sizing: border-box;
      // display: flex;
      // justify-content: space-between;

      .detailed {
        display: flex;
        text-align: justify;
        color: rgba(132, 132, 132, 1);
        font-family: "Noto Sans SC";
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
      }

      .user {
        // margin-top: 20px;
        display: flex;
        align-items: center;

        // gap: 12px;
        // align-items: center;
        .info_img {
          width: 36px;
          height: 36px;
        }

        .info_type {
          display: flex;
          margin-left: auto;
          width: 72px;
          height: 72px;
        }

        .info {
          display: flex;
          flex-direction: column;
          margin-left: 8px;

          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &_top {
            display: flex;
            gap: 8px;

            p {
              font-weight: 600;
              font-size: 20px;
            }

            div {
              padding: 4px 8px;
              border-radius: 20px;
              background: #f5f5f7;
              color: #060000;
              font-size: 14px;
            }
          }

          &_bottom {
            display: flex;
            gap: 8px;
            font-size: 14px;
            color: #727272;
          }
        }
      }

      .btn {
        margin-top: 8px;
        display: flex;
        align-items: center;
        // padding: 12px;
        // border: 1px dashed #c7c7c9;
        border-radius: 30px;
        gap: 12px;
        margin-top: 18px;
        justify-content: flex-end;

        .icon {
          cursor: pointer;
          width: 32px;
          height: 32px;
          border: 1px solid #060000;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .main {
      // flex: 1;
      // background: #fff;
      // border-radius: 30px;
      // padding: 28px;
      box-sizing: border-box;
      overflow: auto;
      height: calc(100vh - 68px - 90px - 42px);
      overflow-x: hidden;

      .line {
        height: 1px;
        background: #f0f0f0;
        margin: 16px 0;
      }

      .info_box {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        // gap: 32px;

        .title {
          display: flex;
          justify-content: space-between;
          font-weight: 600;
          font-size: 16px;
          align-items: center;

          .el-button {
            height: auto;
            background: none;
            color: #1890ff;
            border: 0px;
          }
        }

        .descriptions {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 14px;
          color: #727272;

          div {
            border: 1px dashed #727272;
            padding: 4px 10px;
            border-radius: 20px;
          }
        }
      }
    }

    .footer {
      width: 100%;
      height: 68px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(243, 244, 246, 1);
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-evenly;

      .footer-text {
        color: rgba(27, 27, 27, 1);
        font-family: "Noto Sans SC";
        font-weight: 400;
        font-size: 10px;
        line-height: 11px;
      }
    }
  }

  .right {
    // flex: 0 0 380px;
    // background: #fff;
    // border-radius: 30px;
    // padding: 28px;
    // box-sizing: border-box;
    // overflow: auto;
  }
}

.el-button {
  color: #fff;
  border-radius: 30px;
  background: #060000;
  border: 1px solid #060000;
  height: 32px;
}

.pian_btn {
  border: 1px solid #060000;
  background: #fff;
  color: #060000;
}

::v-deep .el-card__body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}

// :deep(.el-form) {
//   display: flex;
//   flex-direction: column;
// }

:deep(.table-box) {
  // max-width: 600px;
  overflow-y: auto;
  margin-bottom: 40px;
  max-height: 400px;

  .add-btn {
    margin: 20px 0 20px 20px;
  }
}

:deep(.header-row) {
  display: flex;
}

:deep(.table-row) {
  // margin: 0 20px;
  padding: 0 20px;

  .itemContent {
    margin-bottom: 10px;
    display: flex;

    .el-col {
      display: flex;
      border-bottom: 1px solid #dfe6ec;
      justify-content: center;
      align-items: center;
      padding: 40px 20px;
      box-sizing: border-box;
      min-width: 300px;
    }

    .el-form-item {
      margin: 0;
      // width: 100%;
      display: flex;
      align-items: center;

      .el-form-item__label {
        flex-shrink: 0;
      }
    }
  }

  .itemTitle {
    display: flex;
    align-items: center;
    padding: 0 20px;

    .text {
      margin: 20px;
    }
  }
}

:deep(.table-header) {
  text-align: center;
  font-weight: bold;
  background-color: #f8f8f9;
  padding: 10px;
  border-bottom: 1px solid #dfe6ec;
  display: flex;
  min-width: 300px;
  justify-content: center;
}

:deep(.ul-row) {
  border-radius: 10px;
  background-color: #f8f8f9;
  box-sizing: border-box;
  margin: 20px;
  padding: 20px;

  .itemTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

::v-deep .hideUpload .el-button {
  display: none;
}

::v-deep .hideUpload .el-upload {
  display: none;
}

::v-deep .el-button--default {
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 3px !important;
  background: rgb(255, 255, 255) !important;
  border: 1px solid rgb(220, 223, 230) !important;
  color: rgb(96, 98, 102) !important;
}

::v-deep .el-button--primary {
  border-radius: 4px !important;
  border: 1px solid rgb(255 255 255) !important;
  /* padding: 9px 20px !important; */
}

.first_line {
  height: 1px;
  transform: rotate(0.000005deg);
  border: 1px dashed var(--温柔灰, rgba(228, 229, 231, 1));
  margin-top: 16px;
  margin-bottom: 16px;
}

/* 滚动条样式 */
::-webkit-scrollbar {
  width: 0px;
}

/* 滑块样式 */
::-webkit-scrollbar-thumb {
  background: rgba(245, 246, 249, 1);
  border-radius: 10px;
}

/* 滚动条轨道样式 */
::-webkit-scrollbar-track {
  background-color: #fcfcfc;
  border-radius: 2px;
}

::v-deep .handover-dialog {
  .el-dialog__header {
    display: flex;

    .el-dialog__headerbtn {
      top: 8px;
      right: 0px;
    }
  }

  .el-dialog__body {
    padding: 20px 20px;

    .el-form-item {
      display: grid;
      margin-bottom: 18px !important;

      .el-form-item__label {
        margin-bottom: 12px;
      }

      .el-form-item__content {
        .el-input__inner {
          background: #fff !important;
          border: 1px solid rgb(220, 223, 230) !important;
        }
      }
    }
  }

  .el-dialog__footer {
    .dialog-footer {
      display: flex;

      .dialog-footer-btn {
        width: 110px;
        height: 44px;
        border-radius: 8px;
        background: rgba(243, 244, 246, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(132, 132, 132, 1);
        font-family: "Noto Sans SC";
        font-weight: 400;
        font-size: 14px;
      }

      .dialog-footer-btn-determine {
        width: 208px;
        height: 44px;
        border-radius: 8px;
        background: rgba(86, 105, 236, 1);
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(255, 255, 255, 1);
        font-family: "Noto Sans SC";
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

.footer-notApproved{
  width: 84px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid rgba(243, 244, 246, 1);
    color: rgba(132, 132, 132, 1);
    font-family: "Noto Sans SC";
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-adopt{
  width: 84px;
    height: 36px;
    border-radius: 4px;
    background:  rgba(86, 105, 236, 1);
    color: rgba(255, 255, 255, 1);
    font-family: "Noto Sans SC";
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
