import request from '@/utils/request'

// 审批中心

// 我发起的列表
export function getProcessInstanceList(params) {
    return request({
        url: "bpm/process-instance/my-page",
        method: "get",
        params,
    });
}


// 待办列表
export function getTaskList(params) {
    return request({
        url: "bpm/task/todo-page",
        method: "get",
        params,
    });
}

// 已办列表

export function getTaskDoneList(params) {
    return request({
        url: "bpm/task/done-page",
        method: "get",
        params,
    });
}


// 我收到的
export function getProcessReceivedList(params) {
    return request({
        url: "bpm/process-instance/my-received",
        method: "get",
        params,
    });
}

// 转交我的
export function getCommonRecordList(params) {
    return request({
        url: "bpm/common/record",
        method: "get",
        params,
    });
}

// 获取分组
export function getFlowManagementList(params) {
    return request({
        url: "bpm/flowManagement/findGroupAndFlow",
        method: "get",
        params,
    });
}

// 获取人员
export function getArrSimpleList(params) {
    return request({
        url: "system/bpm/user/list-all-simple",
        method: "get",
        params,
    });
}
