var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "keep-alive",
        { attrs: { include: _vm.noCachePages } },
        [
          _vm.refresh
            ? _c("router-view", { key: _vm.$route.path + _vm.key })
            : _vm._e()
        ],
        1
      ),
      _vm.drawer
        ? _c(
            "div",
            [
              _c(
                "el-drawer",
                {
                  attrs: {
                    visible: _vm.drawer,
                    direction: "rtl",
                    modal: false,
                    "custom-class": _vm.backgroundColor
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.drawer = $event
                    }
                  }
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("span", { staticClass: "drawer__title" }, [
                      _vm._v("详情")
                    ])
                  ]),
                  _c(
                    "div",
                    [
                      _c("Detail", {
                        attrs: { drawerData: _vm.drawerData },
                        on: {
                          closeDrawer: _vm.closeDrawer,
                          setBackgColor: _vm.setBackgColor
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }