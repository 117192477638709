var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "navig" } },
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-demo",
          attrs: {
            mode: "horizontal",
            router: "",
            "default-active": this.$route.path
          },
          on: { select: _vm.handleSelect }
        },
        [
          _c("el-menu-item", { attrs: { route: "" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/ioc/Group_1000005379.png"),
                alt: ""
              }
            })
          ]),
          _vm._l(_vm.routes, function(item) {
            return _c(
              "el-menu-item",
              { key: item.path, attrs: { index: item.path } },
              [
                _c("div", [
                  _c("img", {
                    staticStyle: { "margin-right": "5px" },
                    attrs: { src: _vm.getImagePath(item.meta.icon), alt: "" }
                  }),
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(item.meta.title))
                  ])
                ])
              ]
            )
          }),
          _vm.condition
            ? _c(
                "el-menu-item",
                {
                  staticClass: "setUp",
                  staticStyle: { position: "absolute", right: "0px" }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        color: "rgb(144, 147, 153)",
                        display: "flex"
                      },
                      attrs: { size: "medium" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.openBackGround($event)
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: " icon",
                        attrs: {
                          src: require("@/assets/ioc/nut.png"),
                          alt: "Custom Icon"
                        }
                      }),
                      _c("span", [_vm._v("管理后台")])
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _c("keep-alive", [_c("router-view")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }