var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "审批流程",
            visible: _vm.dialogVisible,
            width: _vm.width,
            "modal-append-to-body": false,
            "custom-class": "evaluate-dialog"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
                "label-position": "top"
              },
              on: { close: _vm.close }
            },
            [
              _vm.message == 1
                ? _c("p", { staticStyle: { "font-size": "18px" } }, [
                    _c("i", {
                      staticClass: "el-icon-success",
                      staticStyle: { "margin-right": "10px", color: "#67C23A" }
                    }),
                    _vm._v("您已成功提交该表单，是否需通过该审批？")
                  ])
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "reason" } },
                [
                  !_vm.pass
                    ? _c("div", { staticClass: "required-ioc" }, [_vm._v("*")])
                    : _vm._e(),
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请填写审批建议" },
                    model: {
                      value: _vm.ruleForm.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "reason", $$v)
                      },
                      expression: "ruleForm.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "tag" }, [
            _c("p", { staticStyle: { "margin-bottom": "8px" } }, [
              _vm._v("推荐回复")
            ]),
            _c(
              "div",
              { staticClass: "tag_list" },
              _vm._l(_vm.tagList, function(item, index) {
                return _c(
                  "el-tag",
                  {
                    key: index,
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function($event) {
                        return _vm.chooseTag(item)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "div",
                {
                  staticClass: "cancel",
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "div",
                {
                  staticClass: "submit",
                  on: {
                    click: function($event) {
                      return _vm.fangd("ruleForm")
                    }
                  }
                },
                [_vm._v(" 确定 ")]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }