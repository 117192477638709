var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "评论",
        "modal-append-to-body": false,
        visible: _vm.dialogVisible,
        width: _vm.width,
        "custom-class": "evaluate-dialog"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.close
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "0px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "comment" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", placeholder: "请填写评论" },
                model: {
                  value: _vm.ruleForm.comment,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "comment", $$v)
                  },
                  expression: "ruleForm.comment"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tag", staticStyle: { "margin-bottom": "20px" } },
            [
              _c("p", { staticClass: "title" }, [_vm._v("推荐回复")]),
              _c(
                "div",
                { staticClass: "tag_list" },
                _vm._l(_vm.tagList, function(item, index) {
                  return _c(
                    "el-tag",
                    {
                      key: index,
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          return _vm.chooseTag(item)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                1
              )
            ]
          ),
          _c("div", { staticClass: "file" }, [
            _c(
              "div",
              [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      action: _vm.uploadAction,
                      "show-file-list": false,
                      data: _vm.uploadData,
                      "on-success": function(res, file, fileList) {
                        return _vm.handleSuccess(res, file, fileList, 1)
                      },
                      multiple: "",
                      "before-upload": function(file) {
                        return _vm.beforeAvatarUpload(file, 1)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "el-icon-picture-outline" }),
                    _c("span", [_vm._v("图片")])
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      action: _vm.uploadAction,
                      "show-file-list": false,
                      data: _vm.uploadData,
                      multiple: "",
                      "on-success": function(res, file, fileList) {
                        return _vm.handleSuccess(res, file, fileList, 2)
                      },
                      "before-upload": function(file) {
                        return _vm.beforeAvatarUpload(file, 2)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "el-icon-link" }),
                    _c("span", [_vm._v("附件")])
                  ]
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "picture_list" },
            _vm._l(_vm.ruleForm.picture, function(item, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c("el-image", {
                    staticStyle: { width: "50px", height: "50px" },
                    attrs: {
                      src: item.url,
                      "preview-src-list": _vm.previewList
                    }
                  }),
                  _c("div", { staticClass: "del_box" }, [
                    _c("i", {
                      staticClass: "el-icon-close",
                      staticStyle: { cursor: "pointer", color: "#fff" },
                      on: {
                        click: function($event) {
                          return _vm.delPicture(index)
                        }
                      }
                    })
                  ])
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "attachment_list" },
            _vm._l(_vm.ruleForm.attachment, function(item, index) {
              return _c("div", { key: index }, [
                _c("p", [_vm._v(_vm._s(item.name))]),
                _c(
                  "div",
                  [
                    _c(
                      "el-link",
                      {
                        on: {
                          click: function($event) {
                            return _vm.preview(item)
                          }
                        }
                      },
                      [_vm._v("预览")]
                    ),
                    _c("i", {
                      staticClass: "el-icon-delete",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          return _vm.delAttachment(index)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            }),
            0
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "div",
            {
              staticClass: "cancel",
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v(" 取消 ")]
          ),
          _c(
            "div",
            {
              staticClass: "submit",
              on: {
                click: function($event) {
                  return _vm.debounceSubmitForm("ruleForm")
                }
              }
            },
            [_vm._v(" 确定 ")]
          )
        ]
      ),
      _c("FilePreviewDialog", {
        attrs: {
          "file-url": _vm.fileUrl,
          fileName: _vm.fileName,
          "dialog-visible": _vm.openFilePreview
        },
        on: { closePreviewDialog: _vm.closePreviewDialog }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }