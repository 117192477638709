var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      !_vm.tasks.length
        ? _c("el-empty", {
            attrs: {
              description: _vm.tip,
              image: "https://source.fungsong.com/3452097265742FYF1wx7cNHae.png"
            }
          })
        : _vm._l(_vm.tasks, function(item, index) {
            return _c("div", { key: index, staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c("div", [
                  _c("img", {
                    staticClass: "user_img",
                    attrs: { src: _vm.getTaskImg(item), alt: "" }
                  })
                ]),
                index != _vm.tasks.length - 1
                  ? _c("div", { staticClass: "line" })
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "right" },
                [
                  item.result == 9999
                    ? _c("div", { staticClass: "descriptions" }, [
                        _c("img", {
                          staticStyle: { width: "24px", height: "24px" },
                          attrs: {
                            src:
                              item.avatarUrl ||
                              require("@/assets/ioc/Group_41.png"),
                            alt: ""
                          }
                        }),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(_vm._s(item.startUserName))
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v("发起了审批")
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "descriptions" }, [
                    _c("div", { staticClass: "task" }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  ]),
                  item.assigneeUsers
                    ? _c(
                        "div",
                        { staticClass: "descriptions" },
                        [
                          item.optionList && item.optionList.length
                            ? [
                                _c("optionalUser", {
                                  attrs: {
                                    taskdata: item.optionList[0],
                                    users: _vm.users,
                                    taskDefKey: item.taskDefKey
                                  },
                                  on: { chooseUser: _vm.chooseUser }
                                })
                              ]
                            : _vm._e(),
                          _c("div", [
                            item.assigneeUsers.length == 1
                              ? _c(
                                  "div",
                                  { staticClass: "value scrollBarHidden" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "white-space": "nowrap" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.assigneeUsers[0].userName.indexOf(
                                                "(节点审批人为空，已转交管理员)"
                                              ) == -1
                                                ? item.assigneeUsers[0].userName
                                                : item.assigneeUsers[0].userName.substring(
                                                    0,
                                                    item.assigneeUsers[0].userName.indexOf(
                                                      "(节点审批人为空，已转交管理员)"
                                                    )
                                                  )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    item.assigneeUsers[0].userName.indexOf(
                                      "(节点审批人为空，已转交管理员)"
                                    ) != -1
                                      ? _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "12",
                                              height: "12",
                                              viewBox: "0 0 12 12",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg"
                                            },
                                            on: {
                                              mouseenter: function($event) {
                                                return _vm.onMouseEnter(index)
                                              },
                                              mouseleave: function($event) {
                                                return _vm.onMouseLeave(index)
                                              }
                                            }
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d:
                                                  "M6 1C3.2385 1 1 3.2385 1 6C1 8.7615 3.2385 11 6 11C8.7615 11 11 8.7615 11 6C11 3.2385 8.7615 1 6 1ZM5.625 3.75C5.625 3.543 5.793 3.375 6 3.375C6.207 3.375 6.375 3.543 6.375 3.75V6.03552C6.375 6.24252 6.207 6.41052 6 6.41052C5.793 6.41052 5.625 6.24252 5.625 6.03552V3.75ZM6.01001 8.25C5.73401 8.25 5.50745 8.026 5.50745 7.75C5.50745 7.474 5.729 7.25 6.005 7.25H6.01001C6.28651 7.25 6.51001 7.474 6.51001 7.75C6.51001 8.026 6.28601 8.25 6.01001 8.25Z",
                                                fill: "#848484"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    item.assigneeUsers[0].userName.indexOf(
                                      "(节点审批人为空，已转交管理员)"
                                    ) != -1 && item.showText
                                      ? _c("div", { staticClass: "tooltip" }, [
                                          _vm._v(
                                            " 节点审批人为空 已转交管理员 "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    ref: "featureRef" + index,
                                    refInFor: true,
                                    staticClass: "value_list"
                                  },
                                  _vm._l(item.assigneeUsers, function(sub, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: i,
                                        staticClass: "value scrollBarHidden"
                                      },
                                      [
                                        sub.avatarUrl
                                          ? _c("img", {
                                              attrs: {
                                                src: sub.avatarUrl,
                                                alt: ""
                                              }
                                            })
                                          : _c("img", {
                                              attrs: {
                                                src: require("@/assets/ioc/Group_41.png"),
                                                alt: ""
                                              }
                                            }),
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "white-space": "nowrap"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  sub.userName.indexOf(
                                                    "(节点审批人为空，已转交管理员)"
                                                  ) == -1
                                                    ? sub.userName
                                                    : sub.userName.substring(
                                                        0,
                                                        sub.userName.indexOf(
                                                          "(节点审批人为空，已转交管理员)"
                                                        )
                                                      )
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        sub.userName.indexOf(
                                          "(节点审批人为空，已转交管理员)"
                                        ) != -1
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content:
                                                    "节点审批人为空，已转交管理员",
                                                  placement: "top-start"
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-warning"
                                                })
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                          ])
                        ],
                        2
                      )
                    : _vm._e(),
                  item.result == 10
                    ? _c("div", { staticClass: "descriptions" }, [
                        _c("div", { staticClass: "label" }, [_vm._v("状态")]),
                        _c("div", { staticClass: "value" }, [_vm._v("已转交")])
                      ])
                    : _vm._e(),
                  item.result == 1
                    ? _c("div", { staticClass: "descriptions" }, [
                        _c("div", { staticClass: "label" }, [_vm._v("已等待")]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(_vm._s(_vm.diffTime(item.createTime)))
                        ])
                      ])
                    : _vm._e(),
                  item.reason
                    ? _c("div", { staticClass: "reason" }, [
                        _vm._v(_vm._s(item.reason))
                      ])
                    : _vm._e(),
                  item.comments && item.comments.length
                    ? _vm._l(item.comments, function(e, i) {
                        return _c("div", { key: i, staticClass: "reason" }, [
                          e.isWithdraw == 0
                            ? _c(
                                "div",
                                { staticStyle: { "margin-bottom": "8px" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "name" },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "8px"
                                          }
                                        },
                                        [_vm._v(_vm._s(e.userName) + ":")]
                                      ),
                                      _vm.userId == e.userId
                                        ? [
                                            _vm.revocationFlag(e.createTime)
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteComment(
                                                          e
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("撤销")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  ),
                                  _c("p", [_vm._v(_vm._s(e.comment))]),
                                  e.picture && e.picture.length
                                    ? _c(
                                        "div",
                                        { staticClass: "picture_list" },
                                        _vm._l(e.picture, function(sub, p) {
                                          return _c(
                                            "div",
                                            { key: p },
                                            [
                                              _c("el-image", {
                                                staticStyle: {
                                                  width: "50px",
                                                  height: "50px"
                                                },
                                                attrs: {
                                                  src: sub.url,
                                                  "preview-src-list": e.picture.map(
                                                    function(a) {
                                                      return a.url
                                                    }
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e(),
                                  e.attachment && e.attachment.length
                                    ? _c(
                                        "div",
                                        { staticClass: "attachment_list" },
                                        _vm._l(e.attachment, function(sub, p) {
                                          return _c("div", { key: p }, [
                                            _c("p", [_vm._v(_vm._s(sub.name))]),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-link",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.preview(sub)
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("预览")]
                                                )
                                              ],
                                              1
                                            )
                                          ])
                                        }),
                                        0
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _c("div", [_vm._v("评论已撤回")])
                        ])
                      })
                    : _vm._e()
                ],
                2
              )
            ])
          }),
      _c("FilePreviewDialog", {
        attrs: {
          "file-url": _vm.fileUrl,
          fileName: _vm.fileName,
          "dialog-visible": _vm.openFilePreview,
          type: _vm.type,
          width: _vm.type && _vm.type != "PC" ? "90%" : "50%"
        },
        on: { closePreviewDialog: _vm.closePreviewDialog }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }