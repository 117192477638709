import "./public-path";
import Vue from "vue";
import App from "./App.vue";
import routes from "./router";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import store from "./store";
import VueRouter from "vue-router";
import plugins from "./plugins"; // plugins
// 字典标签组件
import DictTag from "@/components/DictTag";
import actions from "./actions";
import {
  DICT_TYPE,
  getDictDataLabel,
  getDictDatas,
  getDictDatas2
} from "@/utils/dict";
import {
  parseTime,
  resetForm,
  handleTree,
  addBeginAndEndTime,
  divide
} from "@/utils/ruoyi";
import Detail from '@/views/detail/index'

// 全局方法挂载
// Vue.prototype.getDicts = getDicts;
// Vue.prototype.getConfigKey = getConfigKey;
// Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
Vue.prototype.getDictDatas = getDictDatas;
Vue.prototype.getDictDatas2 = getDictDatas2;
Vue.prototype.getDictDataLabel = getDictDataLabel;
Vue.prototype.DICT_TYPE = DICT_TYPE;
// Vue.prototype.handleTree = handleTree;
// Vue.prototype.addBeginAndEndTime = addBeginAndEndTime;
// Vue.prototype.divide = divide;
// Vue.config.productionTip = false

// 全局组件挂载
Vue.component("DictTag", DictTag);
Vue.use(plugins);
Vue.use(Element, { size: "small", zIndex: 3000, appendToBody: false });

let instance = null;

function render(props = {}) {
  const { container, routerBase } = props;
  if (props) {
    actions.setActions(props);
  }
  // redirectPopup(container)
  const router = new VueRouter({
    // base: window.__POWERED_BY_QIANKUN__ ? 'aaa' : process.env.BASE_URL,
    // mode: 'history',
    routes
  });

  Vue.prototype.downloadFile = props.downloadFile;
  Vue.prototype.txsuggestion = props.txsuggestion;
  Vue.prototype.openUserCard = props.openUserCard;
  Vue.prototype.shareApproval = props.shareApproval;
  Vue.prototype.callfn = props.callfn

  instance = new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount(container ? container.querySelector("#app") : "#app");
}

if (!window.__POWERED_BY_QIANKUN__) {
  // 这里是子应用独立运行的环境，实现子应用的登录逻辑

  // 独立运行时，也注册一个名为global的store module
  // commonStore.globalRegister(store)
  // // 模拟登录后，存储用户信息到global module
  // const userInfo = { name: '我是独立运行时名字叫张三' } // 假设登录后取到的用户信息
  // store.commit('global/setGlobalState', { user: userInfo })
  render();
}

export async function bootstrap() {
  console.log("[vue] vue app bootstraped");
}

export async function mount(props) {
  window.fppp = 'ffdd'
  console.log(window.poot)
  console.log('pppppppppppppppppppppppppppppppp')
  render(props)
}

export async function unmount() {
  instance.$destroy();
  instance.$el.innerHTML = "";
  instance = null;
  document.body.appendChild = window.originFn;
}

// function redirectPopup(container) {

//   // 子应用中需要挂载到子应用的弹窗的className，用作标记
//   const addPopup = 'el-dialog__wrapper story-para-add-popup'
//   const editPopup = 'el-dialog__wrapper story-para-edit-popup'
//   const whiteList = [addPopup, editPopup]

//   // 保存原有document.body.appendChild方法
//   window.originFn = document.body.appendChild.bind(document.body)

//   // 重写appendChild方法
//   document.body.appendChild = (dom) => {

//     // 根据标记，来区分是否用新的挂载方式
//     if (whiteList.includes(dom.className)) {
//       container.querySelector('#app').appendChild(dom)
//     } else {
//       window.originFn(dom)
//     }
//   }
// }
